export default httpClient => ({
    login: async (data) => {
      const response = await httpClient.post('/admin/login', data)
      let errors = null

      if (!response.data) {

        errors = {
          status: response.request.status,
          statusText: response.request.responseText
        }

    }
      return {
        data: response.data,
        errors
      }
    },
    logout: async () => {
        localStorage.removeItem('ama')
        return true
    },
    refresh: async (data) => {
      const response = await httpClient.post('/user-manager/refreshtoken', data)

      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
    forgotPassword: async (data) => {
      const response = await httpClient.post('/admin/forgot-password', data)

      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: JSON.parse(response.request.responseText)
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  })
