<template>
  <div class="report-details">
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Nome da publicação</div>
              <div class="card-text">dfasdfdcsdcsdcsdcdsc</div>
              <div class="card-title">Autor da publicação</div>
              <div class="card-text">sdkjfvbsdkjfbsdkjfb</div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-title">Denúncias</div>
              <div class="card-text">
                <div class="user">
                  dfasdfdcsdcsdcsdcdsc - 01/01/2001
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">sldknfskdlnf</div>
                      <div class="card-text">sdlkfjsldkfj</div>
                      <div class="card-text">sdlkfjsldkfj</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-text">
                <div class="user">
                  dfasdfdcsdcsdcsdcdsc - 01/01/2001
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">sldknfskdlnf</div>
                      <div class="card-text">sdlkfjsldkfj</div>
                      <div class="card-text">sdlkfjsldkfj</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div>
                <div class="card-title">
                  Status <br />
                  <div class="badge">Ativo</div>
                </div>
              </div>
              <div>
                <div class="card-title">Ação</div>
                <div class="card-text">
                  <select />
                </div>
              </div>
              <div>
                <div class="card-title">Observação</div>
                <textarea name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <div class="btn btn-primary">Salvar</div>
                <div class="btn btn-danger">Cancelar</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- <Post
              :id="post._id"
              :title="post.post_titulo"
              :user_photo="post.usuario_foto"
              :qtdComments="post.comentario_quantidade"
              :body="post.post_descricao"
              :user="post.usuario_nome"
              :image="post.fotos[0] ? post.fotos[0].foto_url : ''"
              :data="post.data"
              :visivel="post.post_visivel"
              :superUser="user.admin_superuser"
              :comentarios="post.comentarios"
              :reaction="post.reacao"
              :tipo="post.post_tipo"
              :url="post.url"
            /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.card {
  border: none !important;
}

.card-footer {
  border: none !important;
}
</style>
