import jwt from 'jsonwebtoken'
import services from '../services'

import { decrypt, encrypt } from '../utils/decrypt'

export default async (to, from, next) => {

  let userData = null

  if (localStorage.getItem('ama')) {
    const encrypted = localStorage.getItem('ama')
    const decrypted = decrypt(encrypted)
    userData = JSON.parse(decrypted)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (userData) {
      var decoded = jwt.decode(userData.admin_token, { complete: true })
      const expiryDate = new Date(decoded.payload.expiresAt * 1000)
      const actualDate = new Date()

      if (actualDate < expiryDate) {
        next()
        return
      }

      const { data, errors } = await services.auth.refresh({ refresh_token: userData.admin_refresh_token })

      if (!errors) {

        userData.usuario_token = data.admin_token
        userData.usuario_refresh_token = data.admin_refresh_token
        const encrypted = encrypt(JSON.stringify(userData))
        localStorage.setItem('ama', encrypted)

        next()

        return
      } else {
        localStorage.removeItem('ama')

        next('/login')

        return
      }
    }
    next('/login')

    return
  } else {
    if (to.name === 'login' && localStorage.getItem('ama')) {
      next('/dashboard')
      return
    }

    next()
  }
}
