import { decrypt  } from '../../utils/decrypt'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    user: {},
  },
  getters: {
    loading: state => state.isLoading,
    user: state => state.user,
  },
  mutations: {
    setLoading(state, status) {
      state.isLoading = status
    },
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    setLoading({ commit }, status) {
      commit('setLoading', status)
    },
    setUser({ commit }) {
      const encrypted = localStorage.getItem('ama')
      const decrypted = decrypt(encrypted)
      const user = JSON.parse(decrypted)
      commit('setUser', user)
    }
  }
}
