import Vue from 'vue'
import Router from 'vue-router'

import Login from '../views/noAuth/Login.vue'
import ForgotPassword from '../views/noAuth/ForgotPassword.vue'

import Home from '../views/home/Home.vue'

import ChangePassword from '../views/profileConfig/ChangePassword.vue'
import UserProfile from '../views/profileConfig/UserProfile.vue'

import Posts from '../views/posts/Posts.vue'
import ReportsPost from '../views/reports/ReportsPost.vue'
import Reports from '../views/reports/Reports.vue'
import ReportDetails from '../views/reports/ReportDetails.vue'
import ReportsComment from '../views/reports/ReportsComment.vue'
import Users from '../views/users/Users.vue'

import beforeEach from './beforeEach'

const TheContainer = () => import('@/components/TheContainer')

Vue.use(Router)
export let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/dashboard',
      component: TheContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Início',
          component: Home
        },
        {
          path: '/senha',
          name: 'Senha',
          component: ChangePassword
        },
        {
          path: '/perfil',
          name: 'Perfil',
          component: UserProfile
        },
        {
          path: '/usuarios',
          name: 'Usuários',
          component: Users
        },
        {
          path: '/posts',
          name: 'Posts',
          component: Posts
        },
        {
          path: '/denuncias',
          name: 'Denúncias',
          component: Reports
        },
        {
          path: '/denuncias/:id',
          name: 'Denúncia - Detalhes',
          component: ReportDetails
        },
        {
          path: '/denuncias/posts',
          name: 'Denúncias - Post',
          component: ReportsPost
        },
        {
          path: '/denuncias/comentarios',
          name: 'Denúncias - Comentários',
          component: ReportsComment
        },
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/esqueci-minha-senha',
      name: 'forgotPassword',
      component: ForgotPassword
    }
  ]
})

router.beforeEach(beforeEach)

export default router
