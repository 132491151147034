<template>
  <div>
    <button
      class="filter-button mr-3 ml-3"
      @click="filterByCity = !filterByCity"
    >
      Filtrar por cidade
    </button>
    <div
      class="row d-flex justify-content-center m-3 filters-container"
      v-if="filterByCity"
    >
      <div class="col-lg-6 col-md-12">
        <multiselect
          class="mr-2 mb-2 mb-md-0"
          placeholder="Selecione um estado"
          label="estado_sigla"
          track-by="estado_id"
          selectLabel=""
          selectedLabel=""
          :loading="citiesSearching"
          deselectLabel=""
          v-model="state"
          :options="states"
          @input="handleStateSelect"
        ></multiselect>
      </div>
      <div class="col-lg-6 col-md-12">
        <multiselect
          placeholder="Selecione uma cidade"
          label="cidade_nome"
          track-by="cidade_id"
          :disabled="!cities.length"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
          v-model="city"
          :options="cities"
          @input="handleCitySelect"
        ></multiselect>
      </div>
    </div>
    <transition name="bounce">
      <div v-if="!loadingData" class="pt-2">
        <div class="container photo-container">
          <div v-for="(post, id) of posts" :key="id">
            <Post
              :id="post._id"
              :title="post.post_titulo"
              :user_photo="post.usuario_foto"
              :qtdComments="post.comentario_quantidade"
              :body="post.post_descricao"
              :user="post.usuario_nome"
              :image="post.fotos[0] ? post.fotos[0].foto_url : ''"
              :data="post.data"
              :complaints="post.denuncia"
              :visivel="post.post_visivel"
              :superUser="user.admin_superuser"
              :comentarios="post.comentarios"
              :reaction="post.reacao"
              :tipo="post.post_tipo"
              :url="post.url"
            />
          </div>
        </div>
      </div>
    </transition>
    <div v-if="loadingData" class="d-flex">
      <CCard class="center rounded-circle">
        <CCardBody>
          <img
            :src="require(`@/assets/img/waiting-ama.gif`)"
            height="100"
            alt="AMA carregando"
          />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../services";

import { decrypt } from "../../utils/decrypt.js";

import Multiselect from "vue-multiselect";
import Post from "../../components/Post.vue";

export default {
  name: "Home",
  data() {
    return {
      filterByCity: false,
      page: 1,
      totalPages: 1,
      userCount: 0,
      activities: [],
      citiesSearching: false,
      state: [],
      states: [],
      loadingData: true,
      posts: [],
      reactions: [],
      city: null,
      cities: [],
      user: null,
    };
  },
  components: {
    Post,
    Multiselect,
  },
  beforeMount: async function () {
    await this.checkIfUserIsSuper();
    await this.requestReactionsData();
    await this.requestPosts();
    await this.initStates();
    this.loadingData = false;
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async requestPosts() {
      try {
        const { data, errors } = await services.posts.getPostsByCity({
          page: this.page,
          city: this.city,
        });
        if (!errors) {
          this.totalPages = data.total_paginas;
          if (this.page < this.totalPages) {
            this.page = this.page + 1;
          }
          let formatContent = data.data.map((res) => {
            let date = res.post_data_criacao_br.split(" ")[0];
            let time = res.post_data_criacao_br.split(" ")[1];
            let formattedDate = date.split("-").reverse().join("/");
            let formattedTime = time.split(":").slice(0, 2).join(":");
            res.data = `${formattedDate} • ${formattedTime}`;

             return {
              ...res,
            };
          });
          this.posts.push(...formatContent);
          this.formatReactions();
          this.verifyAllVisibility(formatContent);
        }
      } catch (error) {
        Vue.$toast.open({
          message: error.data.error || error.data.message || error.data.erro,
          position: "top",
          type: "error",
        });
      }
    },
    verifyAllVisibility(posts) {
      let count = 0;
      posts.forEach((post) => {
        if (!post.post_visivel) {
          count++;
        }
      });

      if (count === posts.length && !this.user.admin_superuser) {
        this.requestPosts();
      }
    },
    checkIfUserIsSuper() {
      const userEncrypted = localStorage.getItem("ama");
      this.user = JSON.parse(decrypt(userEncrypted));
    },
    formatReactions() {
      this.posts.forEach((post) => {
        post.reacao.forEach((postReaction) => {
          this.reactions.forEach((reaction) => {
            if (postReaction.reacao_id == reaction._id) {
              postReaction.url = reaction.reacao_url;
            }
          });
        });
      });
    },
    async handleCitySelect() {
      this.page = 1;
      this.totalPages = null;
      this.posts = [];
      this.loadingData = true;
      await this.requestPosts();
      this.loadingData = false;
    },
    async handleStateSelect() {
      this.citiesSearching = true;
      if (this.state) {
        await this.requestCities();
      } else {
        this.cities = [];
      }
      this.citiesSearching = false;
    },
    async initStates() {
      const { data, errors } = await services.locals.getStates();
      if (!errors) {
        this.states = data;
      }
    },
    async requestCities() {
      const { data } = await services.locals.getCities({
        estado_id: this.state.estado_id,
      });
      this.cities = data;
    },
    handleScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.requestPosts();
      }
    },
    async requestReactionsData() {
      try {
        const { data, errors } = await services.posts.getReaction();
        if (!errors) {
          this.reactions = data;
        }
      } catch (error) {
        Vue.$toast.open({
          message: error.data.error || error.data.message || error.data.erro,
          position: "top",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
.center {
  margin: 0;
  position: absolute;
  top: 35%;
  left: 50%;
}

.filters-container {
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
}

.filter-button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #4f0dcc;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
}

.filter-button:active {
  outline: none;
  border: none;
}

.filter-button:focus {
  outline: 0;
}

@media (min-width: 1200px) {
  .photo-container {
    max-width: 50%;
  }
}
</style>
