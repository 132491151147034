export default (httpClient) => ({
  addNewPost: async (data) => {
    const response = await httpClient.post("/post", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getAllPosts: async (page) => {
    const response = await httpClient.get(`/feed/${page}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getPostsByCity: async (data) => {
    let request = {};
    if (data.city) {
      request = {
        cidade_id: data.city.cidade_id,
      };
    }
    const response = await httpClient.post(
      `/feed/cidade/${data.page}`,
      request
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  analyzeUrl: async (data) => {
    const response = await httpClient.post("/post/analyze-url", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getODS: async () => {
    const response = await httpClient.get("/util/ods");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getReaction: async () => {
    const response = await httpClient.get("/reaction");
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  deletePost: async (postId) => {
    const response = await httpClient.delete(`/post/${postId}`);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: JSON.parse(response.request.response).error,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getComment: async (payload) => {
    const response = await httpClient.get("/post-comment/" + payload);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  reactPost: async (data) => {
    const response = await httpClient.post("/post-reaction", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  changePostVisibility: async (data) => {
    const response = await httpClient.put("/post/visibility", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  removePostComplaints: async (data) => {
    const response = await httpClient.put("/report/post/remove", data);
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
  getComplaintsByPost: async (data) => {
    const response = await httpClient.post(
      `/report/by-post-id/pagina/${data.page}`,
      {
        post_id: data.post_id,
      }
    );
    let errors = null;

    if (!response.data) {
      errors = {
        status: response.request.status,
        statusText: response.request.statusText,
      };
    }
    return {
      data: response.data,
      errors,
    };
  },
});
