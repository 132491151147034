<template>
  <div class="c-app flex-row align-items-center background-image-container">
    <CContainer>
      <CRow class="justify-content-end">
        <CCol sm="6" md="4">
          <CCardGroup>
            <CCard class="p-3">
              <CCardBody>
                <div
                  class="
                    w-100
                    d-flex
                    justify-content-center
                    align-items-center
                    mb-2
                    ml-4
                    pl-4
                  "
                >
                  <CIcon
                    name="novoLogo"
                    size="custom-size"
                    :height="150"
                    viewBox="0 0 350 110"
                  />
                </div>
                <CForm>
                  <p class="d-flex justify-content-center font-weight-bold">
                    Painel Rede Social
                  </p>
                  <CInput
                    id="email"
                    placeholder="E-mail"
                    autocomplete="email"
                    class="mt-2"
                    v-model="user_email"
                    data-username
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    id="password"
                    placeholder="Senha"
                    type="password"
                    autocomplete="curent-password"
                    v-model="user_password"
                    data-password
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CButton
                    id="login-btn"
                    color="primary"
                    class="px-4 send-login"
                    block
                    @click="login"
                    >Login</CButton
                  >
                  <CButton
                    id="forgot-pwd-btn"
                    color="link"
                    to="/esqueci-minha-senha"
                    block
                    class="px-0"
                    >Esqueci minha senha</CButton
                  >
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../services";

import { encrypt } from "../../utils/decrypt";

export default {
  name: "Login",
  data() {
    return {
      user_email: "",
      user_password: "",
    };
  },
  mounted() {},
  methods: {
    async login() {
      try {
        const { data, errors } = await services.auth.login({
          admin_email: this.user_email,
          admin_senha: this.user_password,
        });
        if (!errors) {
          const encrypted = encrypt(JSON.stringify(data));
          localStorage.setItem("ama", encrypted);
          this.$router.push("/dashboard");
          return;
        } else {
          const errorMessage = JSON.parse(errors.statusText).error
          Vue.$toast.open({
            message: errorMessage || errors.statusText,
            position: "top",
            type: "error",
          });
        }
      } catch (error) {
        localStorage.removeItem("ama");
        Vue.$toast.open({
          message: "Ocorreu um erro ao fazer o login",
          position: "top",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.background-image-container {
  background-image: url("../../assets/img/background.jpg");
  background-size: cover;
}
</style>
