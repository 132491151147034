<template>
<div>
    <h3 class="ml-2">Trocar senha</h3>
    <CContainer>
        <CRow class="justify-content-center">
            <CCol md="5">
                <CCardGroup>
                    <CCard class="p-3">
                        <CCardBody>
                            <CForm>
                                <CAlert v-if="message !== ''" color="warning">
                                    {{message}}
                                </CAlert>
                                <label for="password" class="text-muted">Senha atual</label>
                                <CInput id="password" v-model="password" class="input" type="password" />
                                <label for="newPassword" class="text-muted">Nova senha</label>
                                <CInput id="newPassword" v-model="new_password" class="input" type="password" />
                                <label for="confirmPassword" class="text-muted">Confirme a nova senha</label>
                                <CInput id="confirmPassword" v-model="confirm_password" class="input" type="password" />
                                <CButton color="primary" class="px-4 mt-4" block @click="send">Alterar</CButton>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
</div>
</template>

<script>
import Vue from 'vue'

import services from '../../services'

export default {
    name: 'ChangePassword',
    data() {
        return {
            password: '',
            new_password: '',
            user: {},
            message: '',
            confirm_password: ''
        };
    },
    methods: {
        send: async function () {
            let regEx = /(?:[A-Za-z].*?\d|\d.*?[A-Za-z])/
            if (this.new_password.length < 6) {
                Vue.$toast.open({
                    message: 'A nova senha deve ter pelo menos 6 caracteres.',
                    position: 'top',
                    type: 'error'
                });
                return
            }
            if (!regEx.test(this.new_password)) {
                Vue.$toast.open({
                    message: 'A nova senha deve conter números e letras.',
                    position: 'top',
                    type: 'error'
                });
                return
            }
            if (this.new_password !== this.confirm_password) {
                Vue.$toast.open({
                    message: 'A confirmação da senha deve ser igual a nova senha.',
                    position: 'top',
                    type: 'error'
                });
                return
            }

            let passwords = {
                admin_senha: this.password,
                admin_nova_senha: this.new_password
            }

            const {
                errors
            } = await services.usersManagers.changePassword(passwords)
            if (!errors) {

                Vue.$toast.open({
                    message: 'Senha alterada com sucesso!',
                    position: 'top',
                    type: 'success'
                })

                this.password = ''
                this.new_password = ''
                this.confirm_password = ''
            }
        }
    }
}
</script>

<style>
.progress {
    display: none;
}
</style>
