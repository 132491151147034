export default httpClient => ({
    getUsersManagers: async () => {
        const response = await httpClient.get('/admin')
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    addUserManager: async (data) => {
        const response = await httpClient.post('/admin', data)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    changePassword: async (data) => {
        const response = await httpClient.post('/admin/change-password', data)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    changeStatus: async (data) => {
        const response = await httpClient.put('/admin/status', data)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    editUser: async (data) => {
        const response = await httpClient.put('/admin/profile', data)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    updatePicture: async (data) => {
        const response = await httpClient.put('/admin/profile/picture', data)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
    deleteUser: async (data) => {
        const response = await httpClient.delete(`/admin/${data.admin_id}`)
        let errors = null

        if (!response.data) {
          errors = {
            status: response.request.status,
            statusText: response.request.statusText
          }
      }
        return {
          data: response.data,
          errors
        }
      },
  })
