export const logo = [`
  <defs>
    <clipPath id="clip0">
      <rect id="svg_1" fill="white" height="162.19" width="617.44"/>
    </clipPath>
  </defs>
  <g>
  <title>Layer 1</title>
  <g id="svg_2" clip-path="url(#clip0)">
    <path id="svg_3" fill="#4F0DCC" d="m90.71731,3.91501c-1.4099,-1.41335 -3.1195,-2.49168 -5.0024,-3.15527c-1.8828,-0.6636 -3.8908,-0.89548 -5.8753,-0.67849c-1.9846,0.21698 -3.8949,0.87728 -5.5899,1.93206c-1.695,1.05478 -3.1312,2.47707 -4.2024,4.1617c-7.1187,-4.07087 -15.1819,-6.20019 -23.3824,-6.17478c-8.2004,0.02541 -16.2503,2.20466 -23.3436,6.31956c-7.0933,4.11492 -12.9812,10.02102 -17.07412,17.12712c-4.0929,7.106 -6.24719,15.1626 -6.24719,23.3631c0,8.2005 2.15429,16.2571 6.24719,23.3631c4.09292,7.1061 9.98082,13.0122 17.07412,17.1271c7.0933,4.1149 15.1432,6.2942 23.3436,6.3196c8.2005,0.0254 16.2637,-2.1039 23.3824,-6.1748c1.5484,2.4362 3.8458,4.304 6.5469,5.3225c2.701,1.0185 5.6596,1.1326 8.4311,0.3253c2.7715,-0.8073 5.206,-2.4925 6.9376,-4.8021c1.7315,-2.3097 2.6665,-5.119 2.6644,-8.0057l0,-66.95c0.0029,-1.7506 -0.3412,-3.48443 -1.0123,-5.10129c-0.6711,-1.61686 -1.656,-3.0847 -2.8977,-4.31871l0,0zm-43.41,64.09c-3.9868,-0.0064 -7.8906,-1.1389 -11.2619,-3.2672c-3.3712,-2.1282 -6.0726,-5.1655 -7.7929,-8.7621c-1.7203,-3.5965 -2.3896,-7.6058 -1.9307,-11.5661c0.4588,-3.9603 2.0272,-7.7104 4.5243,-10.8182c2.4972,-3.1079 5.8215,-5.4471 9.59,-6.748c3.7686,-1.301 7.828,-1.5109 11.7106,-0.6055c3.8826,0.9055 7.4305,2.8893 10.2348,5.7231c2.8043,2.8338 4.751,6.4021 5.6158,10.294c0.6681,3.0033 0.6681,6.1167 0,9.12c-1.032,4.7175 -3.6473,8.9397 -7.4112,11.965c-3.7639,3.0253 -8.4498,4.6715 -13.2788,4.665l0,0z"/>
    <path id="svg_4" fill="#89CC49" d="m225.73731,116.65501c-0.003,1.01 -0.12,2.016 -0.35,3c-0.17,0.58 -0.35,1.15 -0.53,1.72c-3.858,11.915 -11.413,22.291 -21.568,29.621c-10.156,7.33 -22.383,11.233 -34.907,11.141c-12.525,-0.091 -24.694,-4.172 -34.741,-11.649c-10.047,-7.477 -17.45,-17.962 -21.134,-29.933c-1.045,-3.38 -0.704,-7.037 0.947,-10.166c1.651,-3.129 4.478,-5.474 7.858,-6.519c3.38,-1.045 7.037,-0.704 10.166,0.947c3.129,1.651 5.474,4.478 6.519,7.858c0,0.07 0.05,0.15 0.07,0.23c2.052,6.58 6.159,12.329 11.718,16.403c5.559,4.075 12.277,6.261 19.17,6.237c6.892,-0.023 13.595,-2.255 19.126,-6.367c5.531,-4.113 9.599,-9.889 11.606,-16.483c0.954,-3.044 2.966,-5.645 5.673,-7.332c2.706,-1.688 5.928,-2.35 9.08,-1.866c3.153,0.484 6.028,2.081 8.104,4.502c2.076,2.422 3.216,5.507 3.213,8.696l-0.02,-0.04z"/>
    <path id="svg_5" fill="#4F0DCC" d="m331.23731,3.91501c-1.41,-1.41335 -3.12,-2.49168 -5.002,-3.15527c-1.883,-0.6636 -3.891,-0.89548 -5.876,-0.67849c-1.984,0.21698 -3.895,0.87728 -5.59,1.93206c-1.695,1.05478 -3.131,2.47707 -4.202,4.16169c-7.119,-4.07086 -15.182,-6.20018 -23.382,-6.17477c-8.201,0.02541 -16.251,2.20466 -23.344,6.31956c-7.093,4.11492 -12.981,10.02102 -17.074,17.12712c-4.093,7.106 -6.247,15.1626 -6.247,23.3631c0,8.2005 2.154,16.2571 6.247,23.3631c4.093,7.1061 9.981,13.0122 17.074,17.1271c7.093,4.1149 15.143,6.2942 23.344,6.3196c8.2,0.0254 16.263,-2.1039 23.382,-6.1748c1.55,2.4316 3.847,4.2949 6.546,5.3103c2.699,1.0154 5.655,1.1282 8.423,0.3214c2.769,-0.8068 5.201,-2.4897 6.932,-4.796c1.731,-2.3064 2.668,-5.112 2.669,-7.9957l0,-66.95c0.002,-1.7497 -0.341,-3.48259 -1.01,-5.09918c-0.669,-1.61659 -1.652,-3.08499 -2.89,-4.32082l0,0zm-43.42,64.09c-3.99,0.0009 -7.899,-1.1264 -11.276,-3.2518c-3.377,-2.1254 -6.085,-5.1624 -7.81,-8.7604c-1.725,-3.5981 -2.397,-7.6105 -1.939,-11.5744c0.457,-3.9638 2.026,-7.7175 4.526,-10.8279c2.499,-3.1104 5.827,-5.4507 9.599,-6.7508c3.773,-1.3001 7.836,-1.5071 11.721,-0.597c3.885,0.91 7.433,2.9 10.236,5.7403c2.803,2.8403 4.745,6.4152 5.603,10.312c0.668,3.0033 0.668,6.1167 0,9.12c-1.039,4.705 -3.653,8.9143 -7.41,11.9312c-3.757,3.0169 -8.432,4.6605 -13.25,4.6588z"/>
    <path id="svg_6" fill="#4F0DCC" d="m182.14731,33.44501l0,1c0,-0.16 0,-0.33 0,-0.49c0,-0.16 -0.01,-0.35 0,-0.51z"/>
    <path id="svg_7" fill="#4F0DCC" d="m227.69731,32.78501l0,47.5c-0.095,3.4734 -1.542,6.7727 -4.032,9.1958c-2.491,2.4232 -5.828,3.779 -9.303,3.779c-3.475,0 -6.812,-1.3558 -9.303,-3.779c-2.49,-2.4231 -3.937,-5.7224 -4.032,-9.1958l0,-46.35c-0.029,-2.444 -1.005,-4.7815 -2.721,-6.5217c-1.716,-1.7402 -4.04,-2.7478 -6.483,-2.8113c-2.443,-0.0634 -4.816,0.8224 -6.62,2.4712c-1.805,1.6489 -2.9,3.9327 -3.056,6.3718c0,0.16 0,0.33 0,0.49c0,0.16 0,0.33 0,0.49l0,45.86c-0.095,3.4734 -1.542,6.7727 -4.032,9.1958c-2.491,2.4232 -5.828,3.779 -9.303,3.779c-3.475,0 -6.812,-1.3558 -9.303,-3.779c-2.49,-2.4231 -3.937,-5.7224 -4.032,-9.1958l0,-46.35c0.041,-1.2662 -0.173,-2.5277 -0.629,-3.7097c-0.456,-1.1819 -1.145,-2.26 -2.026,-3.1703c-0.881,-0.9102 -1.936,-1.634 -3.103,-2.1283c-1.166,-0.4943 -2.42,-0.7491 -3.687,-0.7491c-1.267,0 -2.521,0.2548 -3.687,0.7491c-1.167,0.4943 -2.222,1.2181 -3.103,2.1283c-0.881,0.9103 -1.57,1.9884 -2.026,3.1703c-0.456,1.182 -0.67,2.4435 -0.629,3.7097l0,46.35c-0.095,3.4734 -1.542,6.7727 -4.032,9.1958c-2.491,2.4232 -5.828,3.779 -9.303,3.779c-3.475,0 -6.812,-1.3558 -9.303,-3.779c-2.49,-2.4231 -3.937,-5.7224 -4.032,-9.1958l0,-47.5c0,-1.13 0.06,-2.24 0.17,-3.34c0.682,-6.5383 3.313,-12.7208 7.552,-17.7451c4.239,-5.02434 9.891,-8.65848 16.221,-10.4308c6.33,-1.77232 13.047,-1.60099 19.279,0.49174c6.232,2.09274 11.691,6.01027 15.668,11.24416c3.981,-5.23547 9.444,-9.15292 15.679,-11.24348c6.236,-2.09055 12.957,-2.2576 19.289,-0.47944c6.332,1.77817 11.982,5.41936 16.218,10.45062c4.236,5.0312 6.861,11.22 7.534,17.7623c0.11,1.05 0.17,2.16 0.17,3.29z"/>
  </g>
  </g>
`]
