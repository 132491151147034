<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "assets/scss/style";

.c-active {
  background-color: #89cc49 !important;
}

.c-header-toggler {
  color: black !important;
}

.VueCarousel-navigation-prev,
.VueCarousel-navigation-next {
  color: #2157cc !important;
}

.multiselect__tag {
  background: #89cc49 !important;
}

.multiselect__option--highlight {
  background: #89cc49 !important;
}

.multiselect__option--selected {
  background: #2157cc !important;
  color: white !important;
}

.fc-button {
  background: #2157cc !important;
}

.multiselect__tags {
  background: #fff;
}

.modal-title {
  color: white !important;
}

.fc-daygrid-event {
  cursor: pointer !important;
}

.fc-toolbar-title::first-letter {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.form-control:disabled {
  background-color: #fff !important;
}

.card-header {
  border-color: #fff !important;
  margin-top: 10px;
}

.c-header {
  background: #2157cc !important;
}

.c-header .c-header-toggler-icon {
  filter: invert(67%) sepia(51%) saturate(569%) hue-rotate(42deg) brightness(94%) contrast(98%);
  color: #fff !important;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.dropdown-toggle, .dropdown-item {
  color: #606060 !important;
}

.dropdown-menu {
  border-color: #606060 !important;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
</style>
