export const novoLogo = [`
<svg width="950" height="280" viewBox="0 0 600 130" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.534 44.5621C44.534 44.6333 44.5202 44.7038 44.4933 44.7695C44.4664 44.8353 44.427 44.895 44.3773 44.9453C44.3277 44.9956 44.2687 45.0355 44.2038 45.0628C44.1389 45.09 44.0693 45.104 43.999 45.104H22.2641C16.3588 45.1025 10.6958 42.7255 6.52067 38.4958C2.3455 34.2661 -1.97047e-07 28.53 0 22.5491C0 16.5687 2.34568 10.8333 6.52102 6.60448C10.6964 2.3757 16.3593 0 22.2641 0C28.1695 -1.99569e-07 33.8331 2.37552 38.0093 6.60412C42.1856 10.8327 44.5325 16.5682 44.534 22.5491V44.5621Z" fill="#8ACC0D"/>
<path d="M36.3416 22.1406H32.3893C31.9314 22.1442 31.4888 22.308 31.1365 22.6042C30.7842 22.9005 30.5439 23.311 30.4563 23.7663C29.6681 27.6934 27.2231 30.6592 23.1212 30.6592C19.0193 30.6592 16.58 27.7167 15.7861 23.7896C15.7043 23.3296 15.4663 22.913 15.1133 22.6119C14.7604 22.3108 14.3146 22.1441 13.8531 22.1406H9.90653C9.83297 22.1403 9.76017 22.1556 9.69276 22.1854C9.62535 22.2152 9.56481 22.2589 9.51501 22.3137C9.46521 22.3686 9.42724 22.4333 9.40352 22.5038C9.3798 22.5743 9.37085 22.6491 9.37725 22.7233C9.44629 23.3701 9.55559 24.3839 9.64764 24.85C10.9018 31.4807 16.2061 36.5033 23.1212 36.5033C30.0363 36.5033 35.3463 31.4924 36.6005 24.85C36.6868 24.3839 36.8018 23.3701 36.8709 22.7233C36.8764 22.6493 36.8668 22.5749 36.8428 22.5047C36.8188 22.4346 36.7808 22.3702 36.7311 22.3156C36.6815 22.261 36.6213 22.2173 36.5543 22.1872C36.4873 22.1571 36.4149 22.1412 36.3416 22.1406Z" fill="#3E16CC"/>
<path d="M83.7523 36.3007C83.7508 36.4337 83.6984 36.5609 83.6061 36.6555C83.5138 36.7501 83.3888 36.8046 83.2575 36.8076H82.1069C80.8183 36.8076 79.5181 36.6445 78.4998 35.5083C78.3502 35.3393 77.6253 35.8054 77.2629 36.0035C74.0067 37.7515 69.1684 38.2118 65.5958 37.5359C60.0039 36.4755 55.5855 31.5753 54.504 25.4398C52.4214 13.6642 64.7558 4.56299 76.2043 8.65912C78.302 9.4581 80.305 10.4919 82.176 11.7414C83.5049 12.6154 83.7408 13.973 83.7523 15.4005C83.7638 18.4479 83.7523 33.3816 83.7523 36.3007ZM78.2121 22.3168V19.3219C78.2121 15.185 78.1316 15.0743 74.2196 13.7749C74.1393 13.7397 74.0566 13.7105 73.9722 13.6875C70.3823 12.8601 65.5843 12.8193 62.7078 15.6977C58.3355 20.0385 58.7382 27.3801 63.5074 30.6838C67.3792 33.3582 73.8629 32.6241 77.7749 28.9883C78.0617 28.6236 78.2088 28.1663 78.1891 27.7006C78.2409 25.9118 78.2409 24.123 78.2409 22.3342L78.2121 22.3168Z" fill="#8ACC0D"/>
<path d="M132.762 15.1843C132.79 14.6382 132.689 14.0931 132.465 13.5952C132.242 13.0973 131.904 12.661 131.479 12.3234C129.281 10.4653 126.684 9.15359 123.896 8.49296C121.108 7.83234 118.206 7.84111 115.422 8.5186C113.903 8.99217 112.463 9.69752 111.154 10.6104C110.841 10.8635 110.442 10.9827 110.043 10.9425C109.803 10.8778 109.575 10.7714 109.37 10.6278C108.063 9.71506 106.625 9.00969 105.107 8.53608C102.322 7.85842 99.4189 7.8501 96.6298 8.51176C93.8408 9.17343 91.2433 10.4867 89.0449 12.3467C88.6216 12.6857 88.2844 13.1221 88.0612 13.6197C87.8381 14.1173 87.7355 14.6617 87.762 15.2076C87.808 20.0612 87.762 24.9148 87.762 29.7742C87.762 31.9592 87.5434 35.5193 88.717 36.4865C89.4016 37.0691 90.8341 37.1158 91.139 37.1099C92.1343 37.1099 93.1583 37.3255 93.1583 36.5273C93.1583 35.4144 93.1583 33.0954 93.1583 30.9395C93.1583 28.6729 93.1583 23.0735 93.1583 17.48C93.1318 17.0517 93.2225 16.6244 93.4204 16.245C93.6184 15.8657 93.9159 15.549 94.2802 15.3299C96.2535 13.9024 99.4176 13.5353 101.759 13.6169C105.947 13.7567 107.46 15.5455 107.512 19.8048C107.564 24.0641 107.512 28.2884 107.512 32.5302C107.512 36.772 108.024 37.1041 112.212 37.0808H112.552C112.704 37.0808 112.851 37.0194 112.959 36.9101C113.066 36.8009 113.127 36.6527 113.127 36.4981V35.8689C113.081 29.4595 113.156 25.6548 113.127 19.2746C113.149 18.5633 113.234 17.8555 113.38 17.1595C114.088 14.5783 115.566 13.7043 118.811 13.5936C121.158 13.5179 124.328 13.8791 126.29 15.3066C126.649 15.5335 126.94 15.8565 127.13 16.24C127.32 16.6234 127.401 17.0525 127.366 17.48C127.32 23.0794 127.366 28.6729 127.366 34.2724C127.366 36.4282 127.993 37.0575 130.156 37.075C130.806 37.075 131.462 37.075 132.21 37.075C132.362 37.075 132.509 37.0136 132.617 36.9043C132.724 36.795 132.785 36.6468 132.785 36.4923V29.7451C132.745 24.8915 132.722 20.0379 132.762 15.1843Z" fill="#8ACC0D"/>
<path d="M164.985 36.3007C164.983 36.4337 164.931 36.5609 164.839 36.6555C164.746 36.7501 164.621 36.8046 164.49 36.8076H163.339C162.051 36.8076 160.751 36.6445 159.732 35.5083C159.577 35.3393 158.858 35.8054 158.495 36.0035C155.239 37.7515 150.401 38.2118 146.828 37.5359C141.236 36.4755 136.818 31.5753 135.731 25.4398C133.654 13.6642 145.988 4.56299 157.431 8.65912C159.525 9.40493 161.458 10.6344 163.408 11.7414C164.789 12.5222 164.973 13.973 164.985 15.4005C164.996 18.4479 164.985 33.3816 164.985 36.3007ZM159.445 22.3168V19.3219C159.445 15.185 159.364 15.0743 155.452 13.7749C155.371 13.7406 155.289 13.7114 155.205 13.6875C151.609 12.8601 146.822 12.8426 143.946 15.6977C139.574 20.0385 139.971 27.3801 144.746 30.6838C148.612 33.3582 155.101 32.6241 159.013 28.9883C159.296 28.6216 159.44 28.1653 159.422 27.7006C159.468 25.9118 159.439 24.123 159.445 22.3342V22.3168Z" fill="#8ACC0D"/>
<path d="M56.0978 42.6111C56.2274 42.3523 56.4269 42.1361 56.6731 41.9876C56.9217 41.838 57.2065 41.7613 57.4957 41.7662C57.7688 41.7585 58.039 41.8248 58.2782 41.9585C58.4844 42.075 58.6555 42.2462 58.7729 42.4538V41.8187H59.3482V45.3147H58.7729V44.6737C58.6502 44.8806 58.4781 45.0529 58.2724 45.1748C58.0342 45.3116 57.7637 45.3801 57.49 45.3729C57.199 45.3772 56.9127 45.2979 56.6643 45.1443C56.4159 44.9906 56.2155 44.7689 56.0862 44.5048C55.9398 44.2168 55.8666 43.8964 55.8734 43.5725C55.864 43.2377 55.9413 42.9062 56.0978 42.6111ZM58.6291 42.8616C58.5272 42.6829 58.3804 42.5348 58.2035 42.4325C58.0266 42.3301 57.826 42.2771 57.6223 42.279C57.4207 42.2776 57.2224 42.3298 57.047 42.4305C56.8704 42.5374 56.7254 42.6903 56.627 42.8733C56.52 43.086 56.4666 43.3222 56.4717 43.5608C56.4666 43.8014 56.52 44.0395 56.627 44.2542C56.7246 44.4393 56.8697 44.5943 57.047 44.7029C57.2235 44.8003 57.4213 44.8513 57.6223 44.8513C57.8233 44.8513 58.0211 44.8003 58.1976 44.7029C58.377 44.5952 58.5242 44.4401 58.6233 44.2542C58.7257 44.0398 58.7789 43.8048 58.7789 43.5667C58.7789 43.3285 58.7257 43.0935 58.6233 42.8791L58.6291 42.8616Z" fill="white"/>
<path d="M63.5707 41.9523C63.7786 42.0687 63.9515 42.2398 64.0712 42.4476V41.8183H64.6465V45.3667C64.6462 45.66 64.573 45.9486 64.4337 46.2057C64.3029 46.4532 64.1027 46.656 63.8584 46.7884C63.5911 46.9321 63.2921 47.0043 62.9897 46.9982C62.5813 47.0185 62.1776 46.904 61.839 46.6719C61.6935 46.5719 61.5705 46.4418 61.4783 46.2901C61.386 46.1385 61.3266 45.9687 61.304 45.7921H61.8793C61.9407 46.0054 62.078 46.1881 62.2648 46.3048C62.477 46.4375 62.723 46.5044 62.9724 46.4971C63.1131 46.5025 63.2535 46.479 63.385 46.4279C63.5166 46.3769 63.6365 46.2993 63.7375 46.1999C63.8414 46.0894 63.9216 45.9584 63.9731 45.815C64.0246 45.6716 64.0462 45.519 64.0367 45.3667V44.6384C63.904 44.8594 63.716 45.041 63.4917 45.1649C63.2675 45.2887 63.0149 45.3503 62.7595 45.3434C62.4679 45.347 62.1811 45.2683 61.9311 45.1162C61.6825 44.963 61.4827 44.7405 61.3558 44.4752C61.2093 44.1873 61.1362 43.8668 61.1429 43.543C61.1349 43.219 61.2082 42.8982 61.3558 42.6107C61.4849 42.3515 61.6845 42.1352 61.9311 41.9873C62.1815 41.8368 62.4684 41.7602 62.7595 41.7659C63.0418 41.7506 63.3226 41.8152 63.5707 41.9523ZM63.9159 42.8554C63.8159 42.6717 63.6688 42.5187 63.4902 42.4126C63.3154 42.3099 63.1169 42.2556 62.9149 42.2553C62.7133 42.2539 62.5149 42.3061 62.3396 42.4068C62.1613 42.5116 62.0158 42.665 61.9196 42.8496C61.8125 43.0623 61.7592 43.2985 61.7643 43.5371C61.7592 43.7777 61.8125 44.0158 61.9196 44.2305C61.9933 44.3663 62.093 44.4858 62.2128 44.5821C62.3327 44.6784 62.4703 44.7495 62.6176 44.7913C62.7649 44.833 62.919 44.8446 63.0708 44.8254C63.2226 44.8061 63.3691 44.7564 63.5017 44.6792C63.6811 44.5715 63.8283 44.4164 63.9274 44.2305C64.0298 44.0162 64.083 43.7811 64.083 43.543C64.083 43.3048 64.0298 43.0698 63.9274 42.8554H63.9159Z" fill="white"/>
<path d="M69.7726 43.7688H67.0399C67.0404 43.9187 67.0713 44.0669 67.1307 44.2042C67.1901 44.3415 67.2767 44.465 67.3851 44.5671C67.5936 44.7602 67.8677 44.8646 68.1502 44.8584C68.3684 44.8666 68.5836 44.8054 68.7658 44.6836C68.9271 44.5696 69.048 44.4062 69.111 44.2175H69.7265C69.6326 44.548 69.4295 44.836 69.1512 45.0332C68.8716 45.2189 68.5478 45.325 68.2138 45.3404C67.8798 45.3557 67.5478 45.2798 67.2527 45.1206C67.0059 44.9703 66.8064 44.7521 66.6774 44.4913C66.5259 44.2009 66.4506 43.8758 66.4588 43.5474C66.4501 43.2196 66.5234 42.8949 66.6717 42.6035C66.8002 42.3439 67 42.1275 67.247 41.98C67.5149 41.8442 67.8103 41.7734 68.1099 41.7734C68.4095 41.7734 68.705 41.8442 68.9729 41.98C69.2145 42.1162 69.4137 42.318 69.5482 42.5627C69.6874 42.8239 69.7587 43.1166 69.7553 43.4134C69.7956 43.5299 69.7841 43.6465 69.7726 43.7688ZM69.0362 42.7317C68.945 42.5759 68.8111 42.4504 68.6507 42.3704C68.4713 42.2838 68.2741 42.2419 68.0754 42.2481C67.8035 42.2415 67.5396 42.3417 67.339 42.5277C67.133 42.7314 67.0099 43.0058 66.9939 43.2969H69.1512C69.1658 43.1013 69.1259 42.9055 69.0362 42.7317Z" fill="white"/>
<path d="M74.283 42.1391C74.4194 42.2868 74.5245 42.4612 74.5918 42.6516C74.6591 42.842 74.6872 43.0444 74.6742 43.2462V45.2855H74.0989V43.3278C74.1193 43.0394 74.0262 42.7545 73.84 42.5354C73.7507 42.4407 73.6423 42.3667 73.5221 42.3185C73.402 42.2702 73.273 42.2488 73.1439 42.2557C73.0111 42.2503 72.8786 42.2729 72.7548 42.322C72.631 42.3712 72.5186 42.4458 72.4248 42.5412C72.2344 42.7721 72.1396 43.0685 72.1601 43.3686V45.2855H71.5848V41.7895H72.1601V42.2848C72.2736 42.1071 72.4327 41.9641 72.6204 41.8711C72.8199 41.775 73.0381 41.7253 73.259 41.7254C73.4486 41.7188 73.6376 41.7521 73.8139 41.8233C73.9902 41.8945 74.1499 42.0021 74.283 42.1391Z" fill="white"/>
<path d="M77.3609 42.2951V44.3403C77.3481 44.4679 77.3852 44.5956 77.4644 44.6957C77.5699 44.7759 77.7013 44.8133 77.8326 44.8006H78.2411V45.2842H77.7291C77.472 45.3057 77.2167 45.2264 77.0157 45.0628C76.929 44.9655 76.8633 44.8511 76.8227 44.7267C76.7821 44.6023 76.7675 44.4707 76.7798 44.3403V42.2951H76.3369V41.8174H76.7798V40.9434H77.3551V41.8174H78.2411V42.2951H77.3609Z" fill="white"/>
<path d="M83.1079 43.7705H80.3694C80.3782 44.0718 80.502 44.3579 80.7146 44.5688C80.9238 44.7609 81.1974 44.8651 81.4798 44.8601C81.698 44.869 81.9135 44.8079 82.0953 44.6853C82.2582 44.5716 82.3811 44.4083 82.4463 44.2192H83.0561C82.9605 44.5489 82.7578 44.8363 82.4808 45.0349C82.2012 45.2206 81.8774 45.3267 81.5434 45.3421C81.2093 45.3574 80.8774 45.2815 80.5823 45.1223C80.3255 44.9756 80.1158 44.7572 79.9782 44.493C79.8293 44.2016 79.7542 43.8772 79.7596 43.5491C79.7509 43.2213 79.8241 42.8966 79.9725 42.6052C80.1018 42.3462 80.3014 42.1299 80.5478 41.9817C80.8177 41.8336 81.1211 41.7593 81.428 41.7661C81.7288 41.7587 82.0263 41.831 82.2909 41.9759C82.532 42.1128 82.7311 42.3144 82.8662 42.5586C83.0021 42.8208 83.0713 43.1132 83.0676 43.4093C83.106 43.5258 83.1194 43.6462 83.1079 43.7705ZM82.3945 42.7334C82.3011 42.5774 82.1652 42.452 82.0033 42.3721C81.833 42.2888 81.6459 42.2469 81.4568 42.2498C81.1866 42.2434 80.9246 42.3437 80.7261 42.5294C80.5136 42.7289 80.3875 43.0053 80.3752 43.2986H82.5383C82.5442 43.1002 82.4943 42.9042 82.3945 42.7334Z" fill="white"/>
<path d="M85.4437 45.2091C85.2491 45.1284 85.0787 44.9979 84.9489 44.8304C84.825 44.6618 84.7565 44.4579 84.7533 44.2478H85.3286C85.336 44.3307 85.3609 44.411 85.4016 44.4834C85.4423 44.5558 85.4978 44.6185 85.5645 44.6673C85.7323 44.787 85.9348 44.8465 86.1398 44.8362C86.3214 44.8439 86.5007 44.7929 86.6518 44.6906C86.7085 44.6481 86.7547 44.5928 86.7866 44.529C86.8186 44.4653 86.8354 44.395 86.8359 44.3235C86.8395 44.254 86.823 44.1849 86.7882 44.1248C86.7534 44.0647 86.702 44.0163 86.6403 43.9856C86.443 43.8861 86.2343 43.8117 86.0189 43.7641C85.8069 43.7127 85.5991 43.6446 85.3976 43.5602C85.2383 43.4902 85.0967 43.3847 84.9834 43.2514C84.8746 43.1021 84.8158 42.9213 84.8158 42.7357C84.8158 42.5501 84.8746 42.3694 84.9834 42.2201C85.0969 42.0649 85.2501 41.944 85.4264 41.8705C85.6334 41.7806 85.8571 41.7369 86.0822 41.7423C86.4119 41.724 86.7369 41.8275 86.997 42.0336C87.1141 42.1291 87.2089 42.2497 87.2745 42.3867C87.3402 42.5237 87.3751 42.6738 87.3766 42.8261H86.8014C86.7984 42.7415 86.7773 42.6586 86.7396 42.5832C86.7018 42.5077 86.6483 42.4415 86.5827 42.3891C86.43 42.2749 86.2431 42.2173 86.0535 42.2259C85.8825 42.2182 85.7135 42.2651 85.5702 42.3599C85.5131 42.3979 85.4663 42.4499 85.4342 42.5109C85.402 42.572 85.3855 42.6403 85.3861 42.7095C85.3854 42.8136 85.4246 42.9138 85.4954 42.9892C85.5738 43.0671 85.668 43.1268 85.7716 43.164C85.9215 43.2248 86.0752 43.2754 86.2318 43.3155C86.4269 43.371 86.619 43.4372 86.8071 43.5136C86.962 43.5773 87.0985 43.6795 87.2041 43.8108C87.3197 43.9556 87.3809 44.1373 87.3766 44.3235C87.3784 44.5066 87.3242 44.6857 87.2213 44.8362C87.1086 44.9936 86.9554 45.1166 86.7783 45.1917C86.5734 45.2816 86.3516 45.3253 86.1283 45.3198C85.8952 45.3276 85.6628 45.29 85.4437 45.2091Z" fill="white"/>
<path d="M91.9904 42.6098C92.1218 42.3523 92.3209 42.1365 92.5658 41.9864C92.818 41.8352 93.1069 41.7585 93.4 41.765C93.659 41.7617 93.9145 41.8259 94.1421 41.9514C94.3584 42.0618 94.5397 42.2313 94.6656 42.4409V40.6055H95.2409V45.2668H94.6656V44.6375C94.548 44.8499 94.3748 45.0253 94.1651 45.1444C93.9312 45.2814 93.6643 45.3499 93.3942 45.3425C93.1016 45.3469 92.8137 45.2678 92.5635 45.1143C92.3132 44.9607 92.1106 44.739 91.979 44.4744C91.8352 44.1854 91.7621 43.8656 91.7661 43.5421C91.7643 43.2174 91.8414 42.8973 91.9904 42.6098ZM94.5218 42.8604C94.4199 42.6817 94.2731 42.5336 94.0962 42.4312C93.9193 42.3288 93.7187 42.2759 93.515 42.2777C93.3113 42.2719 93.11 42.3232 92.9333 42.426C92.7567 42.5289 92.6115 42.6792 92.514 42.8604C92.4069 43.073 92.3536 43.3093 92.3586 43.5479C92.3536 43.7884 92.4069 44.0265 92.514 44.2413C92.6133 44.4251 92.758 44.5797 92.9339 44.6899C93.1093 44.7906 93.3077 44.8428 93.5093 44.8414C93.7108 44.8428 93.9092 44.7906 94.0846 44.6899C94.2619 44.5814 94.407 44.4264 94.5045 44.2413C94.6069 44.0269 94.6601 43.7919 94.6601 43.5537C94.6601 43.3156 94.6069 43.0806 94.5045 42.8662L94.5218 42.8604Z" fill="white"/>
<path d="M97.8989 45.1221C97.6392 44.9774 97.4271 44.7586 97.2891 44.4929C97.1356 44.2029 97.0583 43.8779 97.0648 43.5489C97.0568 43.221 97.1363 42.8969 97.2949 42.6109C97.4371 42.3443 97.653 42.1256 97.9162 41.9816C98.1863 41.834 98.4896 41.7597 98.7964 41.766C99.1031 41.7613 99.406 41.8354 99.6766 41.9816C99.9382 42.1251 100.154 42.3414 100.298 42.605C100.455 42.8939 100.534 43.2194 100.528 43.5489C100.533 43.8792 100.452 44.2049 100.292 44.4929C100.144 44.7588 99.9247 44.977 99.6594 45.1221C99.3879 45.2723 99.0827 45.3485 98.7734 45.3435C98.4677 45.3495 98.1659 45.2731 97.8989 45.1221ZM99.343 44.691C99.5296 44.5943 99.6823 44.4417 99.7802 44.254C99.8965 44.0381 99.9541 43.7948 99.947 43.5489C99.954 43.3013 99.8965 43.0561 99.7802 42.8381C99.6851 42.6557 99.539 42.5057 99.3602 42.4069C99.1834 42.3105 98.9857 42.2601 98.7849 42.2601C98.5841 42.2601 98.3864 42.3105 98.2096 42.4069C98.0323 42.5058 97.8881 42.6559 97.7954 42.8381C97.6858 43.0582 97.6323 43.3026 97.6401 43.5489C97.632 43.8035 97.6874 44.056 97.8011 44.2831C97.8902 44.466 98.0329 44.6166 98.2096 44.7143C98.3863 44.8112 98.584 44.8619 98.7849 44.8619C98.9858 44.8619 99.1836 44.8112 99.3602 44.7143L99.343 44.691Z" fill="white"/>
<path d="M109.883 41.9519C110.093 42.0679 110.265 42.2442 110.377 42.4588C110.504 42.711 110.565 42.9917 110.556 43.2745V45.3139H109.98V43.327C110.003 43.0393 109.912 42.7543 109.727 42.5345C109.64 42.4405 109.533 42.3668 109.415 42.3185C109.297 42.2702 109.17 42.2485 109.043 42.2549C108.912 42.2494 108.781 42.2725 108.66 42.3228C108.539 42.3731 108.43 42.4493 108.341 42.5462C108.15 42.7771 108.056 43.0735 108.076 43.3736V45.2847H107.501V43.327C107.523 43.0393 107.432 42.7543 107.248 42.5345C107.16 42.4405 107.054 42.3668 106.936 42.3185C106.817 42.2702 106.69 42.2485 106.563 42.2549C106.432 42.2494 106.302 42.2725 106.181 42.3228C106.06 42.3731 105.951 42.4493 105.861 42.5462C105.671 42.7771 105.576 43.0735 105.597 43.3736V45.2847H105.021V41.7887H105.597V42.2898C105.708 42.1108 105.865 41.9658 106.051 41.8703C106.246 41.7736 106.461 41.7238 106.678 41.7246C106.944 41.7208 107.206 41.7892 107.437 41.9227C107.663 42.0547 107.837 42.2601 107.932 42.5054C108.023 42.2628 108.191 42.0577 108.41 41.9227C108.632 41.7872 108.887 41.7166 109.146 41.7188C109.407 41.731 109.661 41.8113 109.883 41.9519Z" fill="white"/>
<path d="M115.67 43.7688H112.937C112.938 43.9187 112.969 44.0669 113.028 44.2042C113.088 44.3415 113.174 44.465 113.283 44.5671C113.491 44.7602 113.765 44.8646 114.048 44.8584C114.266 44.8666 114.481 44.8054 114.663 44.6836C114.823 44.5679 114.944 44.4051 115.009 44.2175H115.636C115.542 44.548 115.339 44.836 115.06 45.0332C114.781 45.2189 114.457 45.325 114.123 45.3404C113.789 45.3557 113.457 45.2798 113.162 45.1206C112.915 44.9703 112.716 44.7521 112.587 44.4913C112.435 44.2009 112.36 43.8758 112.368 43.5474C112.36 43.2204 112.431 42.8965 112.575 42.6035C112.706 42.3456 112.905 42.1297 113.15 41.98C113.418 41.8442 113.714 41.7734 114.013 41.7734C114.313 41.7734 114.608 41.8442 114.876 41.98C115.118 42.1162 115.317 42.318 115.452 42.5627C115.589 42.8244 115.658 43.1171 115.653 43.4134C115.676 43.526 115.682 43.6445 115.67 43.7688ZM114.957 42.7317C114.866 42.5759 114.732 42.4504 114.571 42.3704C114.392 42.2838 114.195 42.2419 113.996 42.2481C113.724 42.2415 113.46 42.3417 113.26 42.5277C113.054 42.7314 112.93 43.0058 112.914 43.2969H115.072C115.086 43.1013 115.047 42.9055 114.957 42.7317Z" fill="white"/>
<path d="M117.522 41.1428C117.486 41.1065 117.456 41.063 117.437 41.0149C117.417 40.9668 117.407 40.9152 117.407 40.8631C117.407 40.8118 117.416 40.761 117.436 40.7138C117.456 40.6667 117.485 40.6242 117.522 40.5892C117.557 40.5523 117.599 40.523 117.646 40.5029C117.692 40.4829 117.742 40.4726 117.793 40.4727C117.843 40.4719 117.894 40.4819 117.94 40.502C117.987 40.522 118.029 40.5518 118.063 40.5892C118.134 40.6625 118.173 40.7608 118.173 40.8631C118.173 40.9671 118.134 41.0674 118.063 41.1428C118.029 41.1802 117.987 41.21 117.94 41.23C117.894 41.2501 117.843 41.2601 117.793 41.2593C117.742 41.2594 117.692 41.2491 117.646 41.2291C117.599 41.209 117.557 41.1797 117.522 41.1428ZM118.069 41.8187V45.3146H117.494V41.8187H118.069Z" fill="white"/>
<path d="M120.721 45.1221C120.463 44.9758 120.251 44.7574 120.111 44.4929C119.958 44.2029 119.881 43.8779 119.887 43.549C119.882 43.2213 119.961 42.8979 120.117 42.6109C120.259 42.3443 120.475 42.1256 120.738 41.9816C121.009 41.8354 121.312 41.7613 121.619 41.766C121.925 41.7597 122.229 41.834 122.499 41.9816C122.758 42.126 122.972 42.3422 123.114 42.605C123.274 42.8929 123.353 43.219 123.345 43.549C123.35 43.8792 123.268 44.2049 123.109 44.4929C122.961 44.7589 122.741 44.977 122.476 45.1221C122.205 45.2728 121.899 45.3491 121.59 45.3436C121.286 45.3485 120.986 45.2721 120.721 45.1221ZM122.171 44.691C122.355 44.5922 122.505 44.44 122.602 44.254C122.719 44.0381 122.776 43.7948 122.769 43.549C122.778 43.3018 122.722 43.0566 122.608 42.8381C122.509 42.6565 122.362 42.5069 122.182 42.4069C122.006 42.3095 121.808 42.2585 121.607 42.2585C121.406 42.2585 121.208 42.3095 121.032 42.4069C120.854 42.5073 120.709 42.6569 120.612 42.8381C120.502 43.0582 120.449 43.3026 120.457 43.549C120.45 43.7971 120.503 44.0432 120.612 44.2656C120.702 44.4474 120.845 44.5976 121.02 44.6968C121.197 44.7934 121.395 44.8416 121.596 44.8366C121.796 44.8382 121.994 44.7881 122.171 44.691Z" fill="white"/>
<path d="M127.895 42.6094C128.024 42.3503 128.224 42.1339 128.47 41.986C128.718 41.8503 128.995 41.7793 129.276 41.7793C129.557 41.7793 129.834 41.8503 130.081 41.986C130.286 42.1029 130.455 42.2741 130.57 42.4813V41.817H131.146V45.313H130.57V44.6721C130.451 44.8814 130.278 45.0545 130.07 45.1732C129.833 45.3095 129.565 45.378 129.293 45.3713C129.003 45.3757 128.718 45.2969 128.47 45.144C128.222 44.9909 128.022 44.7683 127.895 44.5031C127.749 44.2151 127.675 43.8947 127.682 43.5708C127.669 43.2371 127.743 42.9056 127.895 42.6094ZM130.421 42.86C130.324 42.6754 130.179 42.522 130.001 42.4172C129.826 42.3145 129.627 42.2602 129.425 42.2598C129.224 42.2608 129.026 42.3129 128.85 42.4113C128.676 42.5191 128.533 42.6719 128.436 42.8542C128.329 43.0668 128.275 43.303 128.281 43.5417C128.275 43.7822 128.329 44.0203 128.436 44.2351C128.51 44.3708 128.609 44.4904 128.729 44.5867C128.849 44.6829 128.987 44.754 129.134 44.7958C129.281 44.8376 129.435 44.8492 129.587 44.83C129.739 44.8107 129.885 44.761 130.018 44.6837C130.196 44.5766 130.342 44.4212 130.438 44.2351C130.54 44.0207 130.593 43.7857 130.593 43.5475C130.593 43.3094 130.54 43.0744 130.438 42.86H130.421Z" fill="white"/>
<path d="M138.009 41.9519C138.221 42.0662 138.393 42.243 138.504 42.4588C138.63 42.711 138.692 42.9917 138.682 43.2745V45.3139H138.107V43.327C138.129 43.0393 138.038 42.7543 137.854 42.5345C137.766 42.4405 137.66 42.3668 137.542 42.3185C137.423 42.2702 137.296 42.2485 137.169 42.2549C137.038 42.2494 136.908 42.2725 136.787 42.3228C136.666 42.3731 136.557 42.4493 136.467 42.5462C136.277 42.7771 136.182 43.0735 136.203 43.3736V45.2847H135.627V43.327C135.65 43.0393 135.559 42.7543 135.374 42.5345C135.287 42.4405 135.18 42.3668 135.062 42.3185C134.944 42.2702 134.817 42.2485 134.69 42.2549C134.559 42.2494 134.428 42.2725 134.307 42.3228C134.186 42.3731 134.077 42.4493 133.988 42.5462C133.797 42.7771 133.702 43.0735 133.723 43.3736V45.2847H133.148V41.7887H133.723V42.2898C133.834 42.1108 133.991 41.9658 134.177 41.8703C134.373 41.7736 134.587 41.7238 134.805 41.7246C135.071 41.7208 135.333 41.7892 135.564 41.9227C135.789 42.0547 135.964 42.2601 136.059 42.5054C136.149 42.2628 136.317 42.0577 136.536 41.9227C136.758 41.7872 137.013 41.7166 137.273 41.7188C137.534 41.731 137.787 41.8113 138.009 41.9519Z" fill="white"/>
<path d="M141.754 41.9513C141.999 41.8157 142.274 41.7446 142.554 41.7446C142.833 41.7446 143.108 41.8157 143.353 41.9513C143.6 42.0993 143.8 42.3156 143.929 42.5748C144.076 42.8623 144.15 43.1831 144.142 43.507C144.148 43.8309 144.075 44.1513 143.929 44.4393C143.802 44.7045 143.602 44.9271 143.353 45.0802C143.103 45.2324 142.817 45.3111 142.525 45.3075C142.254 45.3131 141.986 45.2468 141.748 45.1152C141.539 44.9967 141.364 44.8237 141.242 44.6141V45.2492H140.667V40.5879H141.242V42.4466C141.369 42.2406 141.545 42.0702 141.754 41.9513ZM143.411 42.8486C143.315 42.6641 143.169 42.5106 142.991 42.4058C142.815 42.3073 142.617 42.2552 142.416 42.2543C142.214 42.2547 142.015 42.3089 141.84 42.4116C141.659 42.5169 141.511 42.6726 141.415 42.8603C141.309 43.0726 141.254 43.3071 141.254 43.5449C141.254 43.7827 141.309 44.0172 141.415 44.2295C141.514 44.4154 141.661 44.5705 141.84 44.6782C142.015 44.7804 142.214 44.8328 142.416 44.8297C142.617 44.8311 142.816 44.7789 142.991 44.6782C143.169 44.5711 143.315 44.4157 143.411 44.2295C143.518 44.0148 143.571 43.7767 143.566 43.5362C143.57 43.2995 143.517 43.0654 143.411 42.8545V42.8486Z" fill="white"/>
<path d="M145.983 41.1428C145.946 41.1065 145.916 41.0629 145.897 41.0149C145.877 40.9668 145.867 40.9152 145.868 40.8631C145.869 40.76 145.91 40.6616 145.982 40.5887C146.054 40.5158 146.151 40.4742 146.253 40.4727C146.303 40.472 146.352 40.482 146.398 40.5021C146.443 40.5222 146.484 40.5519 146.518 40.5892C146.554 40.6247 146.582 40.6674 146.601 40.7145C146.619 40.7617 146.628 40.8122 146.627 40.8631C146.629 40.9148 146.62 40.9663 146.601 41.0144C146.582 41.0625 146.554 41.1062 146.518 41.1428C146.484 41.1801 146.443 41.2098 146.398 41.2298C146.352 41.2499 146.303 41.26 146.253 41.2593C146.202 41.2601 146.152 41.2501 146.106 41.23C146.059 41.2099 146.017 41.1802 145.983 41.1428ZM146.529 41.8186V45.3146H145.954V41.8186H146.529Z" fill="white"/>
<path d="M151.666 43.7702H148.928C148.937 44.0715 149.06 44.3576 149.273 44.5685C149.482 44.7606 149.756 44.8648 150.038 44.8598C150.257 44.8687 150.472 44.8076 150.654 44.685C150.817 44.5713 150.94 44.408 151.005 44.2189H151.615C151.519 44.5486 151.316 44.836 151.039 45.0346C150.737 45.2458 150.376 45.354 150.01 45.3434C149.706 45.3506 149.405 45.2741 149.141 45.122C148.883 44.9772 148.672 44.7582 148.537 44.4927C148.388 44.2013 148.313 43.8769 148.318 43.5488C148.309 43.221 148.383 42.8963 148.531 42.6049C148.66 42.3459 148.86 42.1296 149.106 41.9814C149.374 41.8447 149.67 41.7734 149.969 41.7734C150.269 41.7734 150.565 41.8447 150.832 41.9814C151.073 42.1183 151.272 42.32 151.408 42.5641C151.543 42.8263 151.613 43.1187 151.609 43.4148C151.659 43.5274 151.678 43.6459 151.666 43.7702ZM150.953 42.7331C150.86 42.5771 150.724 42.4517 150.562 42.3718C150.392 42.2885 150.204 42.2466 150.015 42.2495C149.745 42.2431 149.483 42.3434 149.285 42.5291C149.072 42.7286 148.946 43.005 148.934 43.2983H151.097C151.103 43.0999 151.053 42.9039 150.953 42.7331Z" fill="white"/>
<path d="M156.177 42.139C156.313 42.287 156.417 42.4616 156.483 42.652C156.55 42.8424 156.576 43.0446 156.562 43.2461V45.2854H155.987V43.3276C156.007 43.0392 155.914 42.7544 155.728 42.5352C155.638 42.4404 155.528 42.3664 155.407 42.3182C155.286 42.2699 155.156 42.2486 155.026 42.2555C154.894 42.2502 154.763 42.2729 154.64 42.3221C154.517 42.3712 154.406 42.4458 154.313 42.541C154.123 42.7719 154.028 43.0684 154.048 43.3684V45.2854H153.473V41.7894H154.048V42.2847C154.162 42.107 154.321 41.964 154.509 41.871C154.708 41.7738 154.926 41.7239 155.147 41.7253C155.338 41.7192 155.527 41.7528 155.704 41.824C155.882 41.8951 156.042 42.0023 156.177 42.139Z" fill="white"/>
<path d="M159.249 42.2951V44.3403C159.242 44.404 159.248 44.4684 159.267 44.5295C159.286 44.5907 159.317 44.6472 159.358 44.6957C159.463 44.7775 159.595 44.8151 159.726 44.8006H160.146V45.2842H159.634C159.377 45.3075 159.121 45.228 158.921 45.0628C158.832 44.9666 158.765 44.8525 158.723 44.7279C158.682 44.6033 158.667 44.4712 158.679 44.3403V42.2951H158.236V41.8174H158.679V40.9434H159.255V41.8174H160.152V42.2951H159.249Z" fill="white"/>
<path d="M164.996 43.7704H162.263C162.264 43.9203 162.295 44.0685 162.354 44.2058C162.414 44.3431 162.5 44.4666 162.609 44.5687C162.82 44.7657 163.098 44.8722 163.385 44.8659C163.603 44.874 163.819 44.8129 164.001 44.6911C164.164 44.5789 164.285 44.4149 164.346 44.2249H164.962C164.868 44.5555 164.665 44.8434 164.386 45.0407C164.108 45.2264 163.785 45.3326 163.452 45.348C163.119 45.3634 162.788 45.2874 162.494 45.128C162.235 44.9833 162.025 44.7643 161.89 44.4988C161.738 44.2083 161.663 43.8833 161.671 43.5549C161.662 43.2271 161.735 42.9024 161.884 42.6109C162.012 42.3514 162.212 42.1349 162.459 41.9875C162.731 41.8394 163.036 41.7651 163.345 41.7719C163.646 41.7658 163.943 41.838 164.208 41.9817C164.45 42.1178 164.649 42.3196 164.783 42.5643C164.923 42.8255 164.994 43.1182 164.99 43.415C165.001 43.5332 165.003 43.652 164.996 43.7704ZM164.289 42.7333C164.194 42.5786 164.058 42.4535 163.897 42.3721C163.727 42.2899 163.54 42.2481 163.351 42.2497C163.079 42.2431 162.815 42.3433 162.614 42.5294C162.408 42.733 162.285 43.0074 162.269 43.2985H164.432C164.435 43.1004 164.386 42.9052 164.289 42.7333Z" fill="white"/>
</svg>
`]