<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3 class="ml-2">Usuários</h3>
      </CCardHeader>
      <transition name="bounce">
        <div v-if="!loadingData">
          <CCardBody>
            <CButton
              size="sm"
              color="primary"
              class="ml-2"
              @click="handleAddUser()"
            >
              <CIcon name="cil-plus" class="mr-1" />
              Novo usuário
            </CButton>
            <CDataTable
              :noItemsView="{
                noResults: 'Nenhum usuário encontrado',
                noItems: 'Nenhum usuário encontrado',
              }"
              :items="users"
              :fields="fields"
              column-filter
              :itemsPerPageSelect="{
                label: 'Usuários por página:',
                values: [10, 20, 50],
              }"
              :items-per-page="50"
              hover
              sorter
              pagination
            >
              <template #status="{ item }">
                <td class="py-2">
                  <CSwitch
                    class="mr-1"
                    color="dark"
                    shape="pill"
                    @update:checked="changeStatus(item)"
                    :checked.sync="item.admin_ativo"
                  />
                </td>
              </template>
              <template #edit="{ item }">
                <td class="py-2">
                  <CButton
                    size="sm"
                    color="primary"
                    class="ml-1"
                    @click="handleEdit(item)"
                  >
                    Editar
                  </CButton>
                </td>
              </template>
              <template #delete="{ item }">
                <td class="py-2">
                  <CButton
                    size="sm"
                    color="danger"
                    class="ml-1"
                    @click="handleDelete(item)"
                  >
                    Excluir
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </div>
      </transition>
      <div
        v-if="loadingData"
        class="d-flex justify-content-center align-items-center mt-2 mb-4"
      >
        <img
          :src="require(`@/assets/img/waiting-ama.gif`)"
          class="mb-4"
          alt="AMA carregando"
        />
      </div>
    </CCard>

    <CModal
      :closeOnBackdrop="false"
      title="Novo usuário"
      color="primary"
      :show.sync="addingModal"
      class="mt-4"
    >
      <p>Nome</p>
      <CInput placeholder="Nome" v-model="user.admin_nome" />
      <p>E-mail</p>
      <CInput placeholder="E-mail" v-model="user.admin_email" />
      <p>Senha</p>
      <CInput placeholder="Senha" v-model="user.admin_senha" />
      <p>Admin</p>
      <CSwitch
        class="mr-1"
        color="dark"
        shape="pill"
        :checked.sync="user.admin_superuser"
      />
      <template v-slot:footer>
        <CButton color="primary" @click="addUser">Salvar</CButton>
        <CButton color="primary" @click="(addingModal = false), resetUser"
          >Cancelar</CButton
        >
      </template>
    </CModal>

    <CModal
      :closeOnBackdrop="false"
      title="Editar usuário"
      color="primary"
      :show.sync="editModal"
      class="mt-4"
    >
      <p>Nome</p>
      <CInput placeholder="Nome" v-model="user.admin_nome" />
      <p>E-mail</p>
      <CInput placeholder="E-mail" v-model="user.admin_email" />
      <p>Admin</p>
      <CSwitch
        class="mr-1"
        color="dark"
        shape="pill"
        :checked.sync="user.admin_superuser"
      />
      <template v-slot:footer>
        <CButton color="primary" @click="editUser">Salvar</CButton>
        <CButton color="primary" @click="(editModal = false), resetUser"
          >Cancelar</CButton
        >
      </template>
    </CModal>

    <CModal
      :closeOnBackdrop="false"
      title="Excluir usuário"
      color="danger"
      :show.sync="deleteModal"
      class="mt-4"
    >
      <p>Tem certeza que deseja excluir o usuário?</p>
      <template v-slot:footer>
        <CButton color="danger" @click="deleteUser">Excluir</CButton>
        <CButton color="danger" @click="(deleteModal = false), resetUser"
          >Cancelar</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../services";

import { decrypt } from "../../utils/decrypt.js";

const fields = [
  {
    key: "admin_nome",
    label: "Nome",
    _style: "min-width:200px",
  },
  {
    key: "admin_email",
    label: "E-mail",
    _style: "min-width:80px;",
    filter: false,
  },
  {
    key: "status",
    label: "Status",
    _style: "width:10%",
    sorter: false,
    filter: false,
  },
  {
    key: "edit",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  {
    key: "delete",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Usuários",
  data() {
    return {
      date: new Date(),
      fields,
      users: [],
      editModal: false,
      addingModal: false,
      deleteModal: false,
      isUserActive: false,
      user: {
        admin_nome: "",
        admin_senha: "",
        admin_foto: "",
        admin_email: "",
        admin_superuser: false,
      },
      loadingData: true,
    };
  },
  mounted: async function () {
    const userEncrypted = localStorage.getItem("ama");
    const user = JSON.parse(decrypt(userEncrypted));
    if (!user.admin_superuser) {
      this.$router.push("/dashboard");
      return;
    }
    await this.initUsers();
    this.loadingData = false;
  },
  methods: {
    handleAddUser() {
      this.resetUser();
      this.addingModal = true;
    },
    handleEdit(item) {
      this.user = item;
      this.editModal = true;
    },
    handleDelete(item) {
      this.user = item;
      this.deleteModal = true;
    },
    resetUser() {
      this.user = {
        admin_nome: "",
        admin_senha: "",
        admin_foto: "",
        admin_email: "",
        admin_superuser: false,
      };
    },
    async addUser() {
      const { errors } = await services.usersManagers.addUserManager(this.user);
      if (!errors) {
        await this.initUsers();
        this.resetUser();
        this.validationToast(
          {
            data: {
              message: "Usuário criado com sucesso!",
            },
          },
          "success"
        );
        this.addingModal = false;
      }
    },
    async changeStatus(item) {
      const { errors } = await services.usersManagers.changeStatus({
        ...item,
        usuario_id: item._id,
      });
      if (!errors) {
        await this.initUsers();
        this.resetUser();
        this.validationToast(
          {
            data: {
              message: "Status alterado com sucesso!",
            },
          },
          "success"
        );
      }
    },
    async deleteUser() {
      const { errors } = await services.usersManagers.deleteUser({
        admin_id: this.user._id,
      });
      if (!errors) {
        await this.initUsers();
        this.resetUser();
        this.validationToast(
          {
            data: {
              message: "Usuário excluído com sucesso!",
            },
          },
          "success"
        );
        this.deleteModal = false;
      }
    },
    async editUser() {
      const { errors } = await services.usersManagers.editUser({
        ...this.user,
        admin_id: this.user._id,
      });
      if (!errors) {
        await this.initUsers();
        this.resetUser();
        this.validationToast(
          {
            data: {
              message: "Usuário editado com sucesso!",
            },
          },
          "success"
        );
        this.editModal = false;
      }
    },
    async initUsers() {
      const { data, errors } = await services.usersManagers.getUsersManagers();
      if (!errors) {
        this.users = data.map((item, id) => {
          return {
            ...item,
            id,
          };
        });
      }
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>
