export default httpClient => ({
  getReportedComments: async (page) => {
      const response = await httpClient.get(`/report/${page}`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
    changeStatusOfReport: async (data) => {
      const response = await httpClient.post('/report/status', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: JSON.parse(response.request.responseText).error
        }
    }
      return {
        data: response.data,
        errors
      }
    },
})
