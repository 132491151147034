import axios from 'axios'
import router from '../router'
import store from '../store'

import { decrypt } from '../utils/decrypt'

import AuthService from './auth'
import UsersManagers from './usersManagers'
import Reports from './reports'
import Posts from './posts'
import Locals from './locals'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_URL_API
})

httpClient.interceptors.request.use(config => {
  store.dispatch('global/setLoading', true)

  const encrypted = localStorage.getItem('ama')

  if (encrypted) {
    const decrypted = decrypt(encrypted)
    const userData = JSON.parse(decrypted)
    config.headers.common.Authorization = `Bearer ${userData.admin_token}`
  }

  return config
})

httpClient.interceptors.response.use((response) => {
  store.dispatch('global/setLoading', false)
  return response
}, (error) => {
  const canThrowAnError = error.request.status === 0 ||
    error.request.status === 500

  if (canThrowAnError) {
    store.dispatch('global/setLoading', false)
    throw new Error(error.message)
  }

  if (error.response.status === 401) {
    router.push({ name: 'login' })
  }

  store.dispatch('global/setLoading', false)
  return error
})

export default {
    auth: AuthService(httpClient),
    usersManagers: UsersManagers(httpClient),
    reports: Reports(httpClient),
    posts: Posts(httpClient),
    locals: Locals(httpClient)
}
