export default httpClient => ({
  getCities: async (data) => {
      const response = await httpClient.post('/local/cidades', data)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    },
  getStates: async () => {
      const response = await httpClient.get(`/local/estado`)
      let errors = null

      if (!response.data) {
        errors = {
          status: response.request.status,
          statusText: response.request.statusText
        }
    }
      return {
        data: response.data,
        errors
      }
    }
})
