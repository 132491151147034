<template>
  <div class="iq-card">
    <div class="container-fluid">
      <CModal
        title="Deseja remover as denúncias do post ?"
        size="lg"
        :closeOnBackdrop="false"
        color="primary"
        :show.sync="removeComplaintModal"
        class="mt-4"
      >
        <CRow>
          <CCol xs="12" md="12">
            <CDataTable
              class="mt-4"
              :noItemsView="{
                noResults: 'Nenhuma denúncia encontrada',
                noItems: 'Nenhuma denúncia encontrada',
              }"
              :items="postComplaints"
              :fields="fields"
              :items-per-page="100"
            >
              <template #denuncia_data="{ item }">
                <td class="py-2">
                  {{ formatDateToLocale(item.denuncia_data) }}
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>

        <div
          v-if="loadingComplaints"
          class="d-flex justify-content-center mt-3 mb-3"
        >
          <img
            :src="require(`@/assets/img/waiting-ama.gif`)"
            class="mb-4"
            alt="AMA carregando"
          />
        </div>

        <template v-slot:footer>
          <CButton color="primary">Salvar</CButton>
          <CButton color="primary" @click="removeComplaintModal = false">
            Cancelar
          </CButton>
        </template>
      </CModal>

      <div
        v-if="hasHeaderTitleSlot || hasHeaderActionSlot"
        class="row justify-content-around"
      >
        <div class="col-auto">
          <CImg
            v-if="user_photo"
            rounded="circle"
            fluid
            :src="user_photo"
            class="avatar-img rounded-circle"
          />
          <CImg
            v-else
            rounded="circle"
            fluid
            src="https://cidadeama.com.br/favicon.ico"
            class="avatar-img rounded-circle"
            alt=""
          />
        </div>
        <div class="col p-0">
          <h4 class="card-title">{{ user }}</h4>
          <div class="w-100 data">
            <span
              >{{ data }}
              <span class="super-vision"
                >•
                <span
                  :class="
                    visibility
                      ? 'visibility-badge-green'
                      : 'visibility-badge-red'
                  "
                  >{{ visibility ? "visível" : "não visível" }}
                </span>
              </span>
              <span
                class="ml-2 super-vision"
                v-if="complaints && complaints.denuncia_quantidade"
              >
                <span class="visibility-badge-red">
                  {{
                    complaints.denuncia_quantidade > 1
                      ? `${complaints.denuncia_quantidade} denúncias`
                      : `${complaints.denuncia_quantidade} denúncia`
                  }}
                </span>
              </span>
            </span>
          </div>
        </div>
        <div class="col-auto" v-if="superUser">
          <div class="iq-card-header-toolbar d-flex align-items-center">
            <CDropdown
              right
              toggle-class="text-decoration-none border-0"
              togglerText=""
            >
              <CDropdownItem v-if="visibility" @click="changeVisibility">
                Esconder
              </CDropdownItem>
              <CDropdownItem
                v-if="complaints && complaints.denuncia_quantidade"
                @click="handleRemoveComplaints"
              >
                Remover Denúncias
              </CDropdownItem>
              <CDropdownItem v-if="!visibility" @click="changeVisibility">
                Mostrar
              </CDropdownItem>
            </CDropdown>
          </div>
        </div>
      </div>
    </div>

    <CCardBody v-if="hasBodySlot" :class="'iq-card-body row p-3' + bodyClass">
      <div
        v-if="tipo === 'Youtube'"
        class="w-100 d-flex justify-content-center"
      >
        <div class="video-container">
          <iframe
            :src="formatUrl(url)"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="responsive-iframe"
          ></iframe>
        </div>
      </div>
      <a
        class="d-flex w-100"
        :href="image"
        target="_blank"
        v-if="tipo !== 'Youtube'"
      >
        <CImg :src="image" class="main-img" />
      </a>
      <div class="row d-flex justify-content-between mt-2 w-100">
        <div class="col-12">
          <div class="d-flex mb-2 socialIcons" v-if="reaction.length">
            <div class="col-auto" v-for="(item, id) of reaction" :key="id">
              <div class="d-flex align-items-center">
                <h2 class="react-text pt-2">{{ item.reacao_quantidade }}</h2>
                <div class="react">
                  <img class="d-block react-img" :src="item.url" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!reaction.length"
            class="col-12 d-flex pt-2 w-100 qtd-commentary"
          >
            Nenhuma reação
          </div>
        </div>

        <div class="text mt-3 pl-3 mb-3 w-100 text-center">{{ body }}</div>
        <div
          @click="qtdComments > 0 ? carregarComentario(id) : ''"
          class="col-12 d-flex justify-content-end pt-2 qtd-commentary pointer"
        >
          {{ qtdComments }}
          <span class="pl-1">{{
            qtdComments === 1 ? "comentário" : "comentários"
          }}</span>
        </div>
      </div>
    </CCardBody>
    <CCardFooter v-if="comentarios.length > 0">
      <div class="row">
        <div
          v-for="(item, id) of comentarios"
          :key="id + ' comment'"
          class="col-12 comentarios"
        >
          <div class="row">
            <div class="col-auto">
              <a href="#"
                ><CImg
                  rounded="circle"
                  fluid
                  :src="item.usuario_foto"
                  class="avatar-30 avatar-img rounded-circle"
                  alt=""
              /></a>
            </div>
            <div class="col comment-body">
              <h4 class="card-title">
                {{ item.usuario_nome
                }}<span class="data" v-if="false">
                  - {{ item.comentario_data_criacao }}</span
                >
              </h4>
              <div class="w-100">
                <p class="m-0 p-0">{{ item.comentario }}</p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </CCardFooter>
  </div>
</template>

<script>
import services from "../services";
import { formatDateToLocale } from "../utils/date";
import Vue from "vue";

export default {
  name: "IqCard",
  props: {
    className: {
      type: String,
      default: "",
    },
    user_photo: {
      type: String,
      default: "",
    },
    bodyClass: {
      type: String,
      default: "",
    },
    headerClass: {
      type: String,
      default: "",
    },
    footerClass: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    tipo: {
      type: String,
      default: "",
    },
    visivel: {
      type: Boolean,
      default: false,
    },
    superUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    data: {
      type: String,
      default: "",
    },
    complaints: {
      type: Object,
      default() {
        return {};
      },
    },
    qtdComments: {
      type: Number,
      default: 0,
    },
    video: {
      type: String,
      default: "",
    },
    qtdLikes: {
      type: Array,
      default() {
        return [];
      },
    },
    reaction: {
      type: Array,
      default() {
        return [];
      },
    },
    user: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasHeaderTitleSlot() {
      return !!this.title;
    },
    hasHeaderActionSlot() {
      return !!this.$slots.headerAction;
    },
    hasBodySlot() {
      return !!this.body;
    },
    hasFooterSlot() {
      return !!this.footer;
    },
  },
  data() {
    return {
      formatDateToLocale,
      comentarios: [],
      visibility: this.visivel,
      removeComplaintModal: false,
      loadingComplaints: false,
      postComplaints: [],
      complaintsPagination: {
        page: 1,
        totalPages: 1,
        totalComplaints: 0,
      },
      fields: [
        {
          key: "denuncia_data",
          label: "Data",
          _style: "min-width:100px",
        },
        {
          key: "usuario_denunciante_nome",
          label: "Denunciante",
          _style: "min-width:100px",
        },
        {
          key: "justificativa_titulo",
          label: "Justificativa",
          _style: "min-width:400px",
        },
      ],
    };
  },
  methods: {
    async carregarComentario(id) {
      try {
        const { data, errors } = await services.posts.getComment(id);
        if (!errors) {
          this.comentarios = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async changeVisibility() {
      try {
        const { data, errors } = await services.posts.changePostVisibility({
          id: this.id,
          post_visivel: !this.visibility,
        });
        if (!errors) {
          this.visibility = data.post_visivel;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleRemoveComplaints() {
      this.removeComplaintModal = true;
      await this.getPostComplaints();
    },
    async removeComplaints() {
      try {
        const { errors } = await services.posts.removePostComplaints({
          post_id: this.id,
        });
        if (!errors) {
          this.validationToast(
            {
              data: {
                message: "Denúncias removidas com sucesso ",
              },
            },
            "success"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPostComplaints() {
      this.loadingComplaints = true;
      try {
        const { data } = await services.posts.getComplaintsByPost({
          post_id: this.id,
          page: this.complaintsPagination.page,
        });

        this.complaintsPagination.totalPages = data.total_pages;
        this.complaintsPagination.totalComplaints = data.total_documents;
        this.postComplaints = data.data[0].denuncias;

      } catch (error) {
        console.log(error);
      } finally {
        this.loadingComplaints = false;
      }
    },
    formatUrl(url) {
      if (url.includes("watch?v=")) {
        return url.replace("watch?v=", "embed/");
      }
      if (url.includes("shorts")) {
        return url.replace("shorts", "embed");
      }
      if (url.includes("youtu.be")) {
        return url.replace("youtu.be", "www.youtube.com/embed");
      }
      return url;
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.main-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.iq-card {
  border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
  box-shadow: 0px 2px 4px rgba(70, 120, 240, 0.25);
  background: #fff;
  padding: 15px;
  padding-right: 0px;
  padding-left: 0px;
  margin-bottom: 30px;

  .super-vision {
    text-transform: lowercase;
  }

  .avatar-img {
    height: 45px !important;
    width: 45px !important;
  }

  h4 {
    padding: 0;
    margin: 0;
    font-size: 0.9375rem;
    font-weight: bold;
    color: #8acc0d;
    line-height: 133%;
  }

  .data {
    font-size: 0.8125rem;
    color: #65676b;
    text-transform: uppercase;
    margin: 12px 0;
  }

  .iq-card-body {
    .text {
      text-align: start;
      font-weight: 400;
      line-height: 1.3333;
      color: #000;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .cardButtonDrop {
    color: #000;
    display: block;
    margin-top: -9px;
  }

  .qtd-commentary {
    color: rgb(68, 68, 68);
    font-size: 16px;
  }

  .socialIcons {
    span {
      font-size: 0.9375rem;
    }

    h2 {
      margin-right: 2px;
      font-size: 16px;
    }

    img {
      height: 19px;
    }

    border-radius: 12px;
    padding: 2px;
  }

  .card-footer {
    background-color: #fbfffb;
    border-top: 1px solid #e5e5e5;
  }

  .comentarios {
    h4 {
      font-size: 12px;
    }

    p {
      line-height: 1.3333;
      color: #606060;
      font-size: 12px;
    }

    hr {
      border-color: #dfe1e4;
    }
    .comment-body {
      background: #f4f4f4;
      border-radius: 20px;
      padding: 1rem;
    }
  }

  .react-text {
    font-size: 20px;
    color: #000;
  }

  .react {
    text-align: center;

    .react-img {
      height: 23px;
      width: 23px;
    }
  }
}

.visibility-badge-red {
  background-color: red;
  border-radius: 12px;
  color: white;
  padding: 5px;
  font-weight: bold;
}

.visibility-badge-green {
  background-color: green;
  border-radius: 12px;
  color: white;
  padding: 5px;
  font-weight: bold;
}
</style>
