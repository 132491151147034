import Vue from 'vue'
import Vuex from 'vuex'

import coreUiModule from './modules/coreUiModule'
import globalModule from './modules/globalModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    coreUi: coreUiModule,
    global: globalModule
  }
})
