<template>
<div>
    <CCard>
        <CCardHeader>
            <h3 class="ml-2">Denúncias - Comentários</h3>
        </CCardHeader>
        <transition name="bounce">
            <div v-if="!loadingData">
                <CCardBody>
                    <CDataTable :noItemsView="{ noResults: 'Nenhum comentário encontrado', noItems: 'Nenhum comentário encontrado' }" :items="comments" :fields="fields" column-filter :itemsPerPageSelect="{
                    label: 'Comentários por página:',
                    values: [10, 20, 50]
                }" :items-per-page="50" hover sorter pagination>
                        <template #edit="{item}">
                            <td class="py-2">
                                <CButton size="sm" color="primary" class="ml-1" @click="handleChangeStatus(item)">
                                    Alterar
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <div class="d-flex justify-content-center">
                        <CButton size="sm" variant="outline" color="primary" :disabled="actualPage === 1" @click="changePage('previous')">
                            <CIcon class="mr-1" name="cil-arrow-left" /> Anterior</CButton>
                        <CButton size="sm" color="primary" class="mr-2 ml-2">{{actualPage}}</CButton>
                        <CButton size="sm" variant="outline" color="primary" :disabled="!nextPageExists" @click="changePage('next')">Próxima
                            <CIcon class="ml-1" name="cil-arrow-right" />
                        </CButton>
                    </div>
                </CCardBody>
            </div>
        </transition>
        <div v-if="loadingData" class="d-flex justify-content-center align-items-center mt-2 mb-4">
            <img :src="require(`@/assets/img/waiting-ama.gif`)" class="mb-4" alt="AMA carregando">
        </div>
    </CCard>

    <CModal :closeOnBackdrop="false" title="Alterar status" color="primary" :show.sync="statusModal" class="mt-4">
        <p>Tem certeza que deseja alterar o status do comentário?</p>
        <template v-slot:footer>
            <CButton color="primary" @click="changeStatus">Salvar</CButton>
            <CButton color="primary" @click="statusModal = false">Cancelar</CButton>
        </template>
    </CModal>

</div>
</template>

<script>
import Vue from 'vue'
import services from '../../services'

const fields = [{
        key: 'justificativa_titulo',
        label: 'Título',
        _style: 'min-width:80px;',
        filter: false
    },
    {
        key: 'motivo_denuncia_titulo',
        label: 'Motivo',
        _style: 'min-width:80px;',
        filter: false
    },
    {
        key: 'usuario_analise_nome',
        label: 'Criador',
        _style: 'min-width:200px'
    },
    {
        key: 'usuario_denunciante_nome',
        label: 'Denunciante',
        _style: 'min-width:200px'
    },
    {
        key: 'denuncia_status',
        label: 'Status',
        _style: 'min-width:80px;',
        filter: false
    },
    {
        key: 'edit',
        label: '',
        _style: 'width:1%',
        sorter: false,
        filter: false
    }
]

export default {
    name: 'Usuários',
    data() {
        return {
            date: new Date(),
            fields,
            comments: [],
            statusModal: false,
            actualPage: 1,
            nextPageExists: true,
            reasons: [],
            user: {
                admin_nome: '',
                admin_foto: '',
                _id: '',
            },
            loadingData: true,
            reports: []
        };
    },
    mounted: async function () {
        this.$store.dispatch('global/setUser').then(async () => {
            this.user = this.$store.state.global.user
            await this.initAllData()
            this.loadingData = false
        })
    },
    methods: {
        async initAllData() {
            await this.initComments()
        },
        async changePage(type) {
            if (type == 'next' && this.nextPageExists) {
                this.actualPage++
                this.loadingData = true
                await this.initComments()
            } else if (type == 'previous' && this.actualPage > 1) {
                this.actualPage--
                this.nextPageExists = true
                this.loadingData = true
                await this.initComments()
            }
            this.loadingData = false
        },
        handleChangeStatus(item) {
            this.comment = item
            this.statusModal = true
        },
        async changeStatus() {
            const {
                errors
            } = await services.reports.changeStatusOfReport({
                ...this.comment,
                usuario_nome: this.user.admin_nome,
                usuario_foto: this.user.admin_foto,
                usuario_id: this.user._id
            })
            if (errors) {
                this.validationToast({
                    data: {
                        message: errors.statusText
                    }
                }, 'error')
            }
        },
        async initComments() {
            const {
                data,
                errors
            } = await services.reports.getReportedComments(this.actualPage)
            if (!errors) {

                let temporaryComments = []
                for (let i = 0; i < data.data.length; i++) {
                    const element = data.data[i]

                    if (element.comentario_id) {
                        element.denuncias.map(item => {
                            temporaryComments.push({
                                ...item,
                                ...element,
                                denuncia_id: element._id
                            })
                        })
                    }

                }
                this.comments = temporaryComments

                if (data.total_pages === this.actualPage) {
                    this.nextPageExists = false
                }
            }
        },
        validationToast(err, type) {
            Vue.$toast.open({
                message: err.data.error || err.data.message || err.data.erro,
                position: 'top',
                type: type
            });
        },
    }
}
</script>
