<template>
<div class="c-app flex-row align-items-center background-image-container">
    <CContainer>
        <CRow class="justify-content-end">
            <CCol md="4">
                <CCardGroup>
                    <CCard class="p-3">
                        <CCardBody>
                            <CRow class="w-100 d-flex justify-content-md-center mb-1 pb-4 ml-4 pl-4">
                                <CIcon class="" name="novoLogo" size="custom-size" :height="150" viewBox="0 0 350 110" />
                            </CRow>
                            <CForm>
                                <p class="d-flex justify-content-center font-weight-bold">Informe seu e-mail para recuperar a senha</p>
                                <CInput placeholder="E-mail" autocomplete="phone" v-model="user_mail" class="input">
                                    <template #prepend-content>
                                        <CIcon name="cil-user" /></template>
                                </CInput>
                                <CButton color="primary" class="px-4 send-request" block @click="send">Enviar código</CButton>
                                <CButton color="link" to="/login" block class="px-0">Login</CButton>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
</div>
</template>

<script>
import Vue from 'vue'
import services from '../../services'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            user_mail: "",
            city_choosed: "",
            cityList: []
        };
    },
    mounted() {
        this.cityList = [{
                value: 4106902,
                label: 'Curitiba/PR'
            },
            {
                value: 4205407,
                label: 'Florianópolis/SC'
            },
            {
                value: 3543402,
                label: 'Ribeirão Preto/SP'
            }
        ]
    },
    methods: {
        send: async function () {
            try {
                const {
                    data,
                    errors
                } = await services.auth.forgotPassword({
                    admin_email: this.user_mail,
                })
                if (!errors) {
                    Vue.$toast.open({
                        message: data,
                        position: 'top',
                        type: 'success'
                    })
                } else {
                    Vue.$toast.open({
                        message: errors.statusText.erro || errors.statusText.error,
                        position: 'top',
                        type: 'error'
                    })
                }
            } catch (error) {
                Vue.$toast.open({
                    message: 'Ocorreu um erro ao recuperar a senha',
                    position: 'top',
                    type: 'error'
                });
            }
        }
    }
};
</script>

<style scoped>
.background-image-container {
    background-image: url("../../assets/img/background.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
}
</style>
