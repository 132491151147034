<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3 class="ml-2">Posts</h3>
      </CCardHeader>
      <transition name="bounce">
        <div v-if="!loadingData">
          <CCardBody>
            <CButton
              size="sm"
              color="primary"
              class="ml-2"
              @click="handleAddPost()"
            >
              <CIcon name="cil-plus" class="mr-1" />
              Novo post
            </CButton>
            <CDataTable
              class="mt-4"
              :noItemsView="{
                noResults: 'Nenhum post encontrado',
                noItems: 'Nenhum post encontrado',
              }"
              :items="posts"
              :fields="fields"
              column-filter
              :items-per-page="10"
              hover
              sorter
            >
              <template #delete="{ item }">
                <td class="py-2">
                  <CButton
                    size="sm"
                    color="danger"
                    class="ml-1"
                    @click="handleDeletePost(item)"
                  >
                    Excluir
                  </CButton>
                </td>
              </template>
            </CDataTable>
            <div class="d-flex justify-content-center">
              <CButton
                size="sm"
                variant="outline"
                color="primary"
                :disabled="actualPage === 1"
                @click="changePage('previous')"
              >
                <CIcon class="mr-1" name="cil-arrow-left" /> Anterior</CButton
              >
              <CButton size="sm" color="primary" class="mr-2 ml-2">{{
                actualPage
              }}</CButton>
              <CButton
                size="sm"
                variant="outline"
                color="primary"
                :disabled="!nextPageExists"
                @click="changePage('next')"
                >Próxima
                <CIcon class="ml-1" name="cil-arrow-right" />
              </CButton>
            </div>
          </CCardBody>
        </div>
      </transition>
      <div
        v-if="loadingData"
        class="d-flex justify-content-center align-items-center mt-2 mb-4"
      >
        <img
          :src="require(`@/assets/img/waiting-ama.gif`)"
          class="mb-4"
          alt="AMA carregando"
        />
      </div>
    </CCard>

    <CModal
      title="Novo post"
      size="lg"
      :closeOnBackdrop="false"
      color="primary"
      :show.sync="addingModal"
      class="mt-4"
    >
      <div class="d-flex">
        <div style="width: 50%; margin-right: 8px">
          <p>Tipo</p>
          <CSelect
            placeholder="Selecione um tipo"
            :options="postTypes"
            :value.sync="post.post_tipo"
          ></CSelect>
        </div>

        <div style="width: 50%; margin-left: 8px">
          <p>ODS</p>
          <CSelect
            placeholder="Selecione uma ODS"
            :options="odsList"
            :value.sync="selectedODS"
          ></CSelect>
        </div>
      </div>

      <p>Estado</p>
      <multiselect
        class="pb-2"
        placeholder="Selecione um estado"
        label="estado_sigla"
        track-by="estado_id"
        selectLabel=""
        selectedLabel=""
        :loading="citiesSearching"
        deselectLabel=""
        v-model="state"
        :options="states"
        :multiple="true"
        @input="handleStateSelect"
      ></multiselect>

      <p>Cidade</p>
      <multiselect
        class="pb-2"
        placeholder="Selecione uma cidade"
        label="cidade_nome"
        track-by="cidade_id"
        :disabled="!cities.length"
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        v-model="city"
        :options="cities"
        :multiple="true"
      ></multiselect>

      <div v-if="post.post_tipo !== 'Imagem'">
        <p>URL</p>
        <CRow>
          <CCol xs="12" :md="post.post_tipo === 'Artigo' ? 12 : 9">
            <CInput placeholder="URL" v-model="url" />
          </CCol>
          <CCol xs="12" md="2" v-if="post.post_tipo !== 'Artigo'">
            <CButton color="primary" @click="analyzeUrl" :disabled="loadingUrl"
              >Analisar</CButton
            >
          </CCol>
        </CRow>
      </div>

      <div v-if="post.post_tipo !== 'Youtube'">
        <div v-if="canUploadImage" :class="'form-group mb-0'">
          <input
            @change="handleImageUpload"
            type="file"
            ref="imageInput"
            class="form-control import-photo"
            accept=".jpeg, .jpg, .png"
            id="photos"
          />
          <label class="import-label w-100" for="photos"
            >Escolha as imagens</label
          >
        </div>
        <div v-if="photosList.length > 0">
          <ul class="images-list">
            <li v-for="photo in photosList" :key="photo">
              <div @click="handleImageRemove(photo)" class="remove-image">
                X
              </div>
              <img class="uploaded-image" :src="photo" alt="" />
            </li>
          </ul>
        </div>
      </div>

      <div v-if="!loadingUrl">
        <div>
          <p>Título</p>
          <CInput placeholder="Título" v-model="post.post_titulo" />
        </div>

        <p>Descrição</p>
        <CTextarea placeholder="Descrição" v-model="post.post_descricao" />

        <div v-if="post.post_tipo !== 'Artigo'">
          <p>Descrição longa</p>
          <CTextarea
            placeholder="Descrição longa"
            v-model="post.post_descricao_longa"
          />
        </div>
      </div>

      <div v-else class="d-flex justify-content-center mt-3 mb-3">
        <img
          :src="require(`@/assets/img/waiting-ama.gif`)"
          class="mb-4"
          alt="AMA carregando"
        />
      </div>

      <template v-slot:footer>
        <CButton color="primary" @click="addPost">Salvar</CButton>
        <CButton color="primary" @click="(addingModal = false), resetPost"
          >Cancelar</CButton
        >
      </template>
    </CModal>

    <CModal
      title="Excluir post"
      :closeOnBackdrop="false"
      color="danger"
      :show.sync="deleteModal"
      class="mt-4"
    >
      <p>Tem certeza que deseja excluir o post?</p>
      <template v-slot:footer>
        <CButton color="danger" @click="deletePost">Excluir</CButton>
        <CButton color="danger" @click="deleteModal = false">Cancelar</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import services from "../../services";

import Multiselect from "vue-multiselect";

import { minimizeText } from "../../utils/text";

const fields = [
  {
    key: "titulo_minimizado",
    label: "Título",
    _style: "min-width:200px",
  },
  {
    key: "post_tipo",
    label: "Tipo",
    _style: "min-width:200px",
  },
  {
    key: "usuario_nome",
    label: "Criador",
    _style: "min-width:200px",
  },
  {
    key: "delete",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Posts",
  data() {
    return {
      fields,
      user: {
        admin_nome: "",
        admin_foto: "",
        _id: "",
      },
      posts: [],
      actualPage: 1,
      nextPageExists: true,
      editModal: false,
      deleteModal: false,
      addingModal: false,
      citiesSearching: false,
      states: [],
      containerPhoto: [],
      photosList: [],
      cities: [],
      state: [],
      city: [],
      loadingUrl: false,
      url: "",
      post: {
        usuario_id: "",
        usuario_nome: "",
        usuario_foto: "",
        post_tipo: "",
        post_titulo: "",
        post_descricao: "",
        post_descricao_longa: "",
        url: "",
        fotos: [],
        lista_fotos: [],
        cidade_id: [],
      },
      postTypes: [
        {
          label: "Artigo",
          value: "Artigo",
        },
        {
          label: "Youtube",
          value: "Youtube",
        },
        {
          label: "Imagem",
          value: "Imagem",
        },
      ],
      odsList: [],
      selectedODS: null,
      loadingData: true,
    };
  },
  computed: {
    canUploadImage: function () {
      const isYoutube = this.post.post_tipo === "Youtube";
      const hasType = this.post.post_tipo;
      const isArticle = this.post.post_tipo === "Artigo";

      if (isYoutube || !hasType) return false;

      if (isArticle && this.photosList.length >= 1) return false;

      return true;
    },
  },
  mounted: async function () {
    this.$store.dispatch("global/setUser").then(async () => {
      this.user = this.$store.state.global.user;
      await this.initODS();
      await this.initPosts();
      await this.initStates();
      this.loadingData = false;
    });
  },
  components: {
    Multiselect,
  },
  methods: {
    handleAddPost() {
      this.resetPost();
      this.addingModal = true;
    },
    async handleDeletePost(item) {
      this.deleteModal = true;
      this.postForDelete = item;
    },
    async deletePost() {
      const { errors } = await services.posts.deletePost(
        this.postForDelete._id
      );
      if (!errors) {
        await this.initPosts();
        this.deleteModal = false;
        this.validationToast(
          {
            data: {
              message: "Post excluído com sucesso!",
            },
          },
          "success"
        );
      } else {
        this.deleteModal = false;
        this.validationToast(
          {
            data: {
              message: errors.statusText,
            },
          },
          "error"
        );
      }
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async handleImageUpload({ target }) {
      if (target.files[0].size > 800001) {
        this.validationToast(
          {
            data: {
              message: "O tamanho máximo da imagem é de 800kb",
            },
          },
          "error"
        );

        this.$refs.imageInput.value = "";
        return;
      }

      const file = URL.createObjectURL(target.files[0]);
      this.photosList.push(file);

      await this.blobToBase64(target.files[0]).then((base64) => {
        this.containerPhoto.push(base64);
      });
    },
    handleImageRemove(photo) {
      let index = -1;
      this.photosList.forEach((item, i) => {
        if (item === photo) {
          index = i;
        }
      });

      this.photosList = this.photosList.filter((p) => {
        return p !== photo;
      });

      this.containerPhoto.splice(index, 1);
    },
    formatCities() {
      this.post.cidade_id = [];
      this.city.forEach((item) => {
        this.post.cidade_id.push(item.cidade_id.toString());
      });
    },
    initPost() {
      this.post.usuario_id = this.user._id;
      this.post.usuario_nome = this.user.admin_nome;
      this.post.usuario_foto = this.user.admin_foto;
      if (this.selectedODS) {
        const ods = this.odsList.find((item) => {
          return item.value === this.selectedODS;
        });
        this.post.ods = [
          {
            ods_id: ods.value,
            ods_descricao: ods.text,
          },
        ];
      }

      this.city.length ? this.formatCities() : delete this.post.cidade_id;

      if (this.post.post_tipo === "Imagem") {
        this.post.lista_fotos = this.containerPhoto;
        delete this.post.url;
      } else {
        delete this.post.lista_fotos;
      }

      if (this.post.post_tipo === "Artigo") {
        this.post.post_descricao_longa = this.post.post_descricao;
      }
    },
    async initODS() {
      const { data, errors } = await services.posts.getODS();
      if (!errors) {
        this.odsList = data.result.map((item) => {
          return {
            label: item.ods_id + " - " + item.ods_descricao,
            value: item.ods_id,
            text: item.ods_descricao,
          };
        });

        this.odsList.sort((a, b) => {
          return a.value - b.value;
        });

        this.odsList.unshift({
          label: "Nenhuma",
          value: 0,
        });
      }
    },
    async changePage(type) {
      if (type == "next" && this.nextPageExists) {
        this.actualPage++;
        this.loadingData = true;
        await this.initPosts();
      } else if (type == "previous" && this.actualPage > 1) {
        this.actualPage--;
        this.nextPageExists = true;
        this.loadingData = true;
        await this.initPosts();
      }
      this.loadingData = false;
    },
    resetPost() {
      this.url = "";
      this.city = [];
      this.cities = [];
      this.containerPhoto = [];
      this.photosList = [];
      this.selectedODS = null;
      this.state = [];
      this.post = {
        usuario_id: "",
        usuario_nome: "",
        usuario_foto: "",
        post_tipo: "",
        post_titulo: "",
        post_descricao: "",
        post_descricao_longa: "",
        url: "",
        cidade_id: [],
        lista_fotos: [],
      };
    },
    async addPost() {
      this.initPost();
      this.post.url = this.url;

      if (this.post.post_tipo === "Artigo") {
        if (this.containerPhoto.length > 0) {
          this.post.lista_fotos = [this.containerPhoto[0]];
        } else {
          this.post.lista_fotos = [];
        }
      } else {
        this.post.lista_fotos = [];
        this.containerPhoto.forEach((item) => {
          this.post.lista_fotos.push(item);
        });
      }
      const { errors } = await services.posts.addNewPost(this.post);
      if (!errors) {
        await this.initPosts();
        this.resetPost();
        this.validationToast(
          {
            data: {
              message: "Post criado com sucesso!",
            },
          },
          "success"
        );
        this.addingModal = false;
      } else {
        this.validationToast(
          {
            data: {
              message: "Ocorreu um erro ao criar o post. Tente novamente.",
            },
          },
          "error"
        );
      }
    },
    async handleStateSelect() {
      this.citiesSearching = true;
      let statesId = [];
      this.state.forEach((item) => {
        statesId.push(item.estado_id.toString());
      });
      if (statesId.length) {
        await this.requestCities(statesId);
      } else {
        this.cities = [];
      }
      this.citiesSearching = false;
    },
    async requestCities(statesId) {
      const { data } = await services.locals.getCities({
        estado_id: statesId,
      });
      this.cities = data;
    },
    async initStates() {
      const { data, errors } = await services.locals.getStates();
      if (!errors) {
        this.states = data;
      }
    },
    async initPosts() {
      const { data, errors } = await services.posts.getAllPosts(
        this.actualPage
      );
      if (!errors) {
        this.posts = data.data.map((post) => {
          return {
            ...post,
            titulo_minimizado: minimizeText(post.post_titulo, 50),
          };
        });
        if (data.total_pages === this.actualPage) {
          this.nextPageExists = false;
        }
      }
    },
    async analyzeUrl() {
      this.loadingUrl = true;
      const { data, errors } = await services.posts.analyzeUrl({
        url: this.url,
      });
      if (!errors) {
        this.post.post_descricao_longa =
          data.descricao !== "" ? data.descricao : "-";
        this.post.post_descricao = data.titulo;
        this.post.post_titulo = data.titulo;
        this.post.url = this.url;
      } else {
        this.validationToast(
          {
            data: {
              message: "Não foi possível analisar a URL",
            },
          },
          "error"
        );
      }
      this.loadingUrl = false;
    },
    validationToast(err, type) {
      Vue.$toast.open({
        message: err.data.error || err.data.message || err.data.erro,
        position: "top",
        type: type,
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.images-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  flex: 1;
  list-style: none;
  li {
    position: relative;
    margin: 10px;
    .remove-image {
      cursor: pointer;
      position: absolute;
      top: -8px;
      right: -8px;
      background: red;
      color: #fff;
      font-weight: bold;
      border-radius: 50%;
      border: 1px solid rgba(99, 99, 99, 0.3);
      box-shadow: rgba(99, 99, 99, 0.3) 2px -1px 5px 0px;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.uploaded-image {
  width: 192px;
  height: 192px;
  img {
    object-fit: cover;
  }
}

.import-photo {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.import-photo + label {
  font-size: 1.1rem;
  color: #0d6efd;
  background-color: #fff;
  display: inline-block;
  border: 1px solid #0d6efd;
  line-height: 1.5;
  border-radius: 5px;
  padding: 0.2rem 1rem;
  text-align: center;
  cursor: pointer;
}

.import-photo:focus + label,
.import-photo + label:hover {
  background-color: #0d6efd;
  color: #fff;
}
</style>
