<template>
  <div class="container">
    <div class="card">
      <ul class="nav">
        <li class="nav-item">
          <div class="nav-link active">Pendentes</div>
        </li>
        <li class="nav-item">
          <div class="nav-link active">Solucionadas</div>
        </li>
      </ul>
    </div>
    <div class="card">
      <table class="table">
        <thead>
          <th scope="col">TITULO</th>
          <th scope="col">TIPO</th>
          <th scope="col">DENÚNCIAS</th>
          <th scope="col">DATA</th>
          <th scope="col">STATUS</th>
          <th scope="col">AÇÕES</th>
        </thead>
        <tbody>
          <tr>
            <th colpe="row">#</th>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.card {
  border: none;
  padding: 1rem;
}
.table {
  thead {
    th {
      border-bottom: none;
    }
  }
}
</style>
