<template>
<div>
    <h3 class="ml-2">Atualizar foto</h3>
    <CContainer>
        <CRow class="justify-content-center">
            <CCol md="5">
                <CCardGroup>
                    <CCard class="p-3">
                        <CCardBody>
                            <CForm>
                                <div class="d-flex justify-content-center mb-4">
                                    <vue-avatar :border=0 :width=150 :height=150 ref="vueavatar" @vue-avatar-editor:image-ready="onImageReady" />
                                </div>
                                <CButton color="primary" class="px-4 mt-4" block @click="send">Alterar</CButton>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
</div>
</template>

<script>
import Vue from 'vue'
import {
    VueAvatar
} from 'vue-avatar-editor-improved'

import services from '../../services'

import {
    decrypt
} from '../../utils/decrypt'

export default {
    name: 'ChangePassword',
    async mounted() {
        const encrypted = localStorage.getItem('ama')
        const decrypted = await decrypt(encrypted)
        this.user_name = JSON.parse(decrypted).admin_nome
        this.user_id = JSON.parse(decrypted)._id
    },
    data() {
        return {
            message: '',
            user_photo: null,
        }
    },
    components: {
        VueAvatar
    },
    methods: {
        onImageReady() {
            this.user_photo = this.$refs.vueavatar.getImage().toDataURL()
        },
        send: async function () {
            const data = {
                admin_id: this.user_id,
                admin_foto: this.user_photo
            }

            const {
                errors
            } = await services.usersManagers.updatePicture(data)
            if (!errors) {
                Vue.$toast.open({
                    message: 'Foto alterada com sucesso!',
                    position: 'top',
                    type: 'success'
                })
            }
        }
    }
}
</script>

<style>
.progress {
    display: none;
}
</style>
