import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueToast from 'vue-toast-notification'
import ViaCep from 'vue-viacep'
import VueSweetalert2 from 'vue-sweetalert2'
import datePicker from 'vue-bootstrap-datetimepicker'
import InfiniteLoading from 'vue-infinite-loading'
import VueMask from 'v-mask'

import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'leaflet/dist/leaflet.css'

const infiniteLoadingOptions = {
  props: {
    spinner: 'default',
  },
  system: {
    throttleLimit: 50,
  },
  slots: {
    noMore: '',
  },
}

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(ViaCep)
Vue.use(VueToast)
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(datePicker)
Vue.config.productionTip = false
Vue.use(InfiniteLoading, infiniteLoadingOptions)

new Vue({
  router,
  store,
  icons,
  render: (h) => h(App),
}).$mount('#app')
